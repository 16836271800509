<template>
    <div class="modal fade" 
        :class="modalPosition" 
        :id="modalID" tabindex="-1" 
        :aria-labelledby="theModalLabel" 
        aria-hidden="true"
        @click.self="$emit('closeModal')">
        <div class="modal-dialog modal-dialog-centered w-100" :class="modalSize">
            <div class="modal-content shadow">
                <div class="modal-header border-0 pt-2 ps-3 pe-3 pb-2">
                    <slot name="header-left"></slot>
                    <div class="d-flex justify-content-end flex-grow-1">
                        <button type="button" class="cobalt-toolbar__icon-btn" data-bs-dismiss="modal" aria-label="Закрыть">
                            <img src="@/assets/images/dashboard/base-close-dark.svg" />
                        </button>
                    </div>
                    <slot name="header-right"></slot>
                </div>
                <div class="ps-3 pe-3">
                    <slot name="body"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from "bootstrap";
export default {
    props: {
        modalID: {
            type: String,
            required: true
        },
        modalSize: {
            type: String,
            default: ''  // или любой другой дефолт
        },
        modalPosition: {
            type: String,
            default: ''  // или любой другой дефолт
        }
    },    
    data() {
        return {
            theModal: null,
            theModalLabel: null
        }
    },
    mounted: function () {
        var IdModal = '#' + this.$props.modalID
        this.theModalLabel = this.modalID + "Label"

        this.theModal = new Modal(IdModal, {backdrop: true})
    },
    methods: {
        shows() {
            this.theModal.show()
        },
        hide() {
            this.theModal.hide()
        }
    }
}
</script>

<style>
.modal {
    backdrop-filter: blur(5px);
}
</style>