<template>
    <div class="cover-uploader" :class="!previewImage ? 'no-cover-selected' : ''">
      <div v-if="previewImage" class="preview">
        <div class="cover-preview" :style="{ 'background-image': 'linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7)), url(' + Config.SERVER + 'web/' + previewImage + ')'}">
          <img :src="Config.SERVER + 'web/' + previewImage" />
        </div>
        <div class="cover-actions">
          <div class="btn btn-base edit-cover"  @click="triggerFileInput">
            <img src="@/assets/images/dashboard/base-edit-dark.svg">
          </div>          
          <div class="btn btn-base btn-warning delete-cover" @click="onRemoveCover">
            <img src="@/assets/images/dashboard/base-delete-dark.svg">
          </div>
        </div>
      </div>
      <div v-else class="placeholder" @click="triggerFileInput">
        <span>{{ $t("UI.task.addCover") }}</span>
      </div>
      <input type="file" ref="fileInput" @change="onFileChange" accept="image/*" style="display: none" />
    </div>
  </template>
  
  <script>
  import Config from '../config.js'

  export default {
    name: "CoverUploader",
    props: {
      initialImage: {
        type: String,
        default: null,
      },
      updateCover: {
        type: Function,
        required: true,
      },
      removeCover: {
        type: Function,
        required: true,
      },      
    },
    data() {
        return {
            Config,
            previewImage: this.initialImage
        }
    },
    methods: {
        onFileChange(event) {
            const file = event.target.files[0]
            if (file) {
                this.previewImage = URL.createObjectURL(file)
                this.$emit("file-selected", file)
                this.updateCover(file)
            }
        },
        async onRemoveCover() {
          await this.removeCover()
          this.$refs.fileInput.type = 'text'
          this.$refs.fileInput.type = 'file'
        },
        triggerFileInput() {
            this.$refs.fileInput.click()
        },
    },
    watch: {
      initialImage(newImage) {
        this.previewImage = newImage
      },
    },
  };
  </script>
  
  <style scoped>
  .cover-uploader {
    width: 100%;
    height: 110px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: border-color 0.3s;
  }
  .cover-uploader:hover {
    border-color: #888888;
  }
  .cover-uploader.no-cover-selected {
    border: 1px dashed #cccccc;    
    height: 32px;
  }
  .placeholder {
    background-color: transparent;
    color: #aaaaaa;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }
  .preview {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
  }
  .preview .cover-preview {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cover-preview img {
    max-height: 100%;
    height: 100%;
  }
  .cover-actions {
    display: flex;
    flex-direction: column;
    padding: 0 5px;
  }
</style>
  