import * as rolesApi from '@/api/roleApi.js';

export default {
    namespaced: true,
    state() {
        return {
            companyListRoles: []
        }
    },
    getters: {
        getListRoles(state) {
            return state.companyListRoles
        },
    },
    mutations: {
        setListRoles(state, listData) {
            state.companyListRoles = JSON.parse(listData)
        },
    },
    actions: {
        async listRoles({ commit }, id) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                const dataResp = await rolesApi.listRoles(userData.token, id)

                if (dataResp) {
                    commit('setListRoles', JSON.stringify(dataResp))
                }
            }
        },
        async updateRole({ commit }, data) {
            // console.log(data);
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                // console.log(id);

                const dataResp = await rolesApi.update(userData.token, data)

            }
        },
        async createRole({ commit }, data) {
            // console.log(data);
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                // console.log(id);

                const dataResp = await rolesApi.create(userData.token, data)

            }
        },
        async delRole({ commit }, id) {
            // console.log(data);
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                // console.log(id);

                const dataResp = await rolesApi.gelCompany(userData.token, id)

            }
        },
    }
}