import { createRouter, createWebHistory } from "vue-router"

import store from "@/store"
import AppHome from "../views/Dashboard";
import AppLogin from "../views/Login";
import AppRegister from "../views/Register";
import AppE404 from '../views/E404';
import AppCompanies from '../views/Home';
import AppCompany from '../views/Company';
import AppProject from '../views/ProjectOne';
import AppInvite from '../views/Invite';

const routes = [
    {
        name: 'dashboard',
        path: '/',
        component: AppHome,
        meta: { auth: true, title: "NEXPlan - Home" }
    },
    {
        name: 'companies',
        path: '/companies',
        component: AppCompanies,
        meta: { auth: true, title: "NEXPlan - Projects"  }
    },    
    {
        name: 'company',
        path: '/company/:id',
        component: AppCompany,
        meta: { auth: true, title: "NEXPlan - Company"  }
    },
    {
        name: 'project',
        path: '/project/:id',
        component: AppProject,
        meta: { auth: true, title: "NEXPlan - Project"  }
    },
    {
        name: 'login',
        path: '/login',
        component: AppLogin,
        meta: { title: "NEXPlan - Login"  }
    },
    {
        name: 'register',
        path: '/register',
        component: AppRegister,
        meta: { title: "NEXPlan - Register"  }
    },
    {
        name: 'invite',
        path: '/invite',
        component: AppInvite,
        meta: { title: "NEXPlan - Invite"  },
        props: (route) => ({ code: route.query.code, from: route.query.from }) 
    },
    {
        path: '/:any(.*)', // .*
        component: AppE404
    }
];

const router = createRouter({
    routes,
    history: createWebHistory()
});

router.beforeEach((to, from, next) => {
    let isLogin = localStorage.getItem('user')
    
    document.title = to.meta.title || 'COBALT';

    if (to.meta.auth && !isLogin) {
        // console.log(isLogin)
        next({ name: 'login' })
    } else {
        if (to.name == 'login' && isLogin) {
            next({ name: 'home' });
        } else {
            next();
        }
    }

    next();

});

export default router;