<template>
    <div class="eventTask" @click="showModTask(task.id, statusId)">
        <div class="eventTask__title">
            <div class="eventTask__title-title">
                {{ task.title }}
            </div>
            <div class="cobalt-task__priority ms-2" 
                :style="{ 
                    'color': task.priorityObj.color,
                    'background-color': task.priorityObj.bg
                }
            ">
                {{ task.priorityObj.label }}
            </div>               
        </div>
        <div class="eventTask__description">
            {{ task.description }}
        </div> 
        <div class="eventTask__footer">
            <div class="cobalt-task__performer" v-if="task.performer != ''">
                <CobaltPerformersList :performers="task.performer" />
            </div>   
            
            <div class="eventTask__footer-info mt-2">
                <div v-if="task.date_planned_end > 0" class="cobalt-task__deadline" :class="deadlineColor">{{ daysTo }} {{ $t("UI.task.daysTo") }}</div>
                <div class="cobalt-task__comments ms-2" v-if="task.comments.length > 0">
                    {{ (task.comments.length).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) }}
                </div>
                <div class="cobalt-task__done ms-2" v-if="task.subtask.length > 0">
                    {{ task.subtask.length }}
                </div>                    
                <div class="cobalt-task__attachment ms-2" v-if="task.files.length > 0">
                    {{ (task.files.length).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) }}
                </div>                    
            </div>            
        </div>
    </div>   
</template>

<script>
import { localeDate, priorityOptions, lightenDarkenColor } from '@/utils/format'
import CobaltPerformersList from "@/components/dashboard/parts/CobaltPerformersList.vue"
import { nameColor } from '@/utils/format'
import Config from '../config.js'

export default {
    components: {
        CobaltPerformersList
    },
    name: 'TaskCardEvent',
    props: {
        task: {
            type: Object,
            required: true
        },
        statusId: {
            type: Number,
            required: true
        }
    },
    computed: {
        daysTo: {
            get() {
                const end   = this.task.date_planned_end
                const current =  new Date().getTime() / 1000

                if ( end > 0 && current < end) {   
                    return parseInt( ( end - current ) / 86400 )
                }
                return 0
            }
        }     
    },
    data() {
        return {
            Config,
            overlap: false 
        }
    },
    methods: {
        localeDate,
        lightenDarkenColor,
        nameColor,
        showModTask(taskId, statusId) {
            this.$emit('showModTask', taskId, statusId);
        }       
    }
}
</script>