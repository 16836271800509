import * as userApi from '@/api/userApi.js';

export default {
    namespaced: true,
    state() {
        return {
            user: null,
            userData: null
        }
    },
    // state: {
    //     user: null,
    //     userData: null
    // },
    getters: {
        // isLogin: state => state.user !== null,
        isLogin: state => false,
        getUser(state) {
            return state.userData
        }
    },
    mutations: {
        SET_USER_TOKEN(data, userData) {
            // console.log(js_data);
            this.state.user = userData
            localStorage.setItem('user', JSON.stringify(userData))
        },
        CLEAR_USER_DATA() {
            localStorage.removeItem('user')
            this.state.user = null
            this.state.userData = null
        },
        setUserData(data, userData) {
            this.state.userData = JSON.parse(userData)
            // console.log(this.state.userData)
        },

    },
    actions: {
        async isLoggedIn({ commit }) {
            const userString = localStorage.getItem('user')
            if (userString) {
                return true
            } else {
                return false
            }
        },
        async login({ commit }, { login, password }) {
            console.log(login);
            let data = await userApi.login(login, password);
            if (data) {
                commit('SET_USER_TOKEN', data)
                return data
            } else {
                return null
            }

        },
        async autoLogin({ commit }) {
            const userString = localStorage.getItem('user')
            if (userString) {
                const userData = JSON.parse(userString)

                // console.log(userData);

                const dataResp = await userApi.setData(userData.token)

                // console.log(dataResp);

                if (dataResp) {
                    commit('SET_USER_TOKEN', userData)
                    commit('setUserData', JSON.stringify(dataResp))
                } else {
                    commit('CLEAR_USER_DATA')
                    location.reload()
                }
            }
            else {

            }
        },
        async register({ commit }, data) {
            const dataResp = await userApi.register(data)
            return dataResp
        },
        async activate({ commit }, key) {
            const dataResp = await userApi.activate(key)
            return dataResp
        },
        logout({ commit }) {
            commit('CLEAR_USER_DATA')
        },    
        async recent({ commit }) {
            // console.log(data);
            const userString = localStorage.getItem('user')
            let dataResp = []
            
            if (userString) {
                const userData = JSON.parse(userString)
                dataResp = await userApi.recentProjects(userData.token)
            }

            return dataResp
        },        
        async userInfo({ commit }) {
            const userString = localStorage.getItem('user')
            let dataResp = []
            
            if (userString) {            
                const userData = JSON.parse(userString)
                dataResp = await userApi.userInfo(userData.token)
            }
            return dataResp
        },   
    }
}