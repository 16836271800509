<template>

    <CobaltDashboard :info="null" :company="prInfo" :project="pojctInfo" :withFilterBar="true" contentClasses="p-0" @switchMode="switchDashboardMode" @showMod="showMod">
        <div v-if="this.dashboardMode == 'canban'">
            <VueDraggable v-model="listStat" class="cobalt-statuses__wrapper" animation="200" :force-fallback="true" :scroll-sensitivity="200" @end="onEnd">
            <div class="cobalt-status-column__wrapper" v-for="elemStat in listStat" :key="elemStat.id">
                <div class="cobalt-status-column">
                    <div class="cobalt-status-column__header d-flex align-items-center"
                        :style="{ 'border-color': elemStat.color != '#000000' ? elemStat.color : null }"
                    >
                        <span class="cobalt-bullet" :style="{ 'background-color': elemStat.color != '#000000' ? elemStat.color : null }"></span>
                        <h6 class="title">{{ elemStat.title }} <span class="tasks-count" v-if="tasks[elemStat.id] && tasks[elemStat.id].length > 0">{{`0${tasks[elemStat.id].length}`.slice(-2)}}</span></h6>
                        <div class="d-flex align-items-left">
                            <button type="button" class="btn btn-link btn-sm text-body-tertiary pe-0"
                                @click="showModTask(0, elemStat.id)"><img src="@/assets/images/dashboard/task-add.svg"></button>                                        

                            <div class="dropdown">
                                <button class="btn btn-link btn-sm text-body-tertiary pe-0" :id="'taskDropdownMenuLink' + elemStat.id" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src="@/assets/images/dashboard/more-vertical.svg">
                                </button>
                                <ul class="dropdown-menu dropdown-menu" :aria-labelledby="'taskDropdownMenuLink' + elemStat.id">
                                    <li class="dropdown-item"><button type="button" class="btn btn-link btn-sm me-2 text-body-tertiary"
                                        @click="showModStatRole(elemStat.id)"><font-awesome-icon
                                            :icon="['fas', 'users-gear']" /> {{ $t("UI.statusRoles") }}</button></li>
                                    <li class="dropdown-item"><button type="button" class="btn btn-link btn-sm me-2 text-body-tertiary"
                                        @click="showMod(elemStat.id)"><font-awesome-icon
                                            :icon="['fas', 'pencil']" /> {{ $t("UI.changeStatus") }}</button></li>
                                    <li class="dropdown-item"><button type="button" class="btn btn-link btn-sm text-body-tertiary"
                                        @click="showDel(2, elemStat.id)"><font-awesome-icon
                                            :icon="['fas', 'trash']" /> {{ $t("UI.deleteStatus") }}</button></li>
                                </ul>
                            </div>                                            
                        </div>
                    </div>
                    <div class="cobalt-status-column__body pt-2 pb-2">
                        <h6 class="mb-0 p-3 text-center" v-if="tasks[elemStat.id].length == 0">{{ $t("message.noRecordsAvailable") }}</h6>
                        <VueDraggable class="drop-zone" v-model="tasks[elemStat.id]" animation="200"  :force-fallback="true" :scroll-sensitivity="200" group="tasks" @end="onEnd">
                            <TaskCard
                                v-for="elemTask in tasks[elemStat.id]"
                                :key="elemTask.id"
                                :task="getPreparedTask(elemTask)"
                                :statusId="elemStat.id"
                                :statuses="listStat"
                                @showModTask="showModTask"
                                @showArch="showArch"
                                @showDel="showDel"
                                @showMoveTo="showMoveTo"                                
                            />
                        </VueDraggable>
                    </div>
                </div>
            </div>         
            </VueDraggable>
            <!--
            <div class="overflow-hidden ps-4">
                <button class="btn btn-lg cobalt-status-column__button" @click="showMod(0)">{{ $t("UI.addStatus") }}</button>
            </div>          
            -->
        </div>
        
        <div v-if="this.dashboardMode == 'calendar'" class="is-light-mode">
            <div class="d-flex w-100">
                <div class="cobalt-status-column__wrapper">
                    <div class="cobalt-status-column">
                        <div class="cobalt-status-column__header d-flex align-items-center">
                            <h6 class="title">{{ $t("UI.task.todayTask")}}<span class="tasks-count">{{ todayTasks.length }}</span></h6>
                        </div>
                        <div class="cobalt-status-column__body pt-2 pb-2">
                            <TaskCard
                                v-for="elemTask in todayTasks"
                                :key="elemTask.task.id"
                                :task="getPreparedTask(elemTask.task)"
                                :statusId="elemTask.statusId"
                                :statuses="listStat"
                                @showModTask="showModTask"
                                @showArch="showArch"
                                @showDel="showDel"
                                @showMoveTo="showMoveTo"                                
                            />
                        </div>
                    </div>
                </div>         
                <div style="flex: 1; overflow: hidden;">
                    <Qalendar 
                        :key="calendarKey" 
                        :config="this.calendarConfig" 
                        :events="calendarEvents"
                        :event-was-clicked="calendarEventClick"
                    >
                        <template #dayCell="{dayData}">
                            <div class="cobalt-calendar__day-wrapper">
                                <div class="cobalt-calendar__day"> {{ dayData.dateTimeString.substring(8, 10) }}</div>

                                <div class="cobalt-calendar__events" v-if="dayData.events.length > 0">
                                    <div class="cobalt-calendar__event" 
                                        v-for="event in dayData.events" 
                                        :key="event.id" 
                                        @click="this.showModEvent(event.tasks)"
                                        :style="{ 'background-color': event.color.bg, 'border-color': event.color.color }"
                                    >
                                        <span :style="{ 'color': event.color.color }">{{ Object.values(event.tasks).flat().length }}</span>
                                    </div> 
                                </div>
                            </div>
                        </template>

                        <template #eventDialog="props">
                            <div v-if="props.eventDialogData && props.eventDialogData.title">
                                <div :style="{marginBottom: '8px'}">Edit event</div>

                                <input class="flyout-input" type="text" :style="{ width: '90%', padding: '8px', marginBottom: '8px' }" >

                                <button class="close-flyout" @click="props.closeEventDialog">
                                Finished!
                                </button>
                            </div>
                        </template>                        
                    </Qalendar>
                </div>
            </div>
        </div>
    </CobaltDashboard>

    <BsModal  :modalID='modalID' modalPosition="right" ref="BsModal" class="cobalt-base-dialog">
        <template v-slot:header-left>{{ modalTitle }}</template>
        <template v-slot:body>
            <div class="modal-body">
                <form @submit.prevent="updateStatus(0)">
                    <div class="mb-3">
                        <label for="statusTitle">{{ $t("UI.name") }}:</label>
                        <input type="text" class="form-control" id="statusTitle" aria-describedby="loginHelp"
                            v-model="datUpdateStatus.title">
                        <small id="loginHelp" class="form-text text-muted"></small>
                    </div>
                    <div class="mb-4">
                        <label for="statusDescription">{{ $t("UI.description") }}:</label>
                        <textarea class="form-control" id="statusDescription"
                            v-model="datUpdateStatus.description"></textarea>
                    </div>
                    <hr>
                    <div class="mb-1">
                        <label>{{ $t("UI.backgroundColor") }}</label>
                    </div>
                    <div class="mb-4">
                        <div class="form-check form-check-inline" v-for="itemColor in colors">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                :checked="itemColor == datUpdateStatus.color" @change="chColor(itemColor)"
                                id="inlineRadio1" :value="itemColor"
                                :style="{ 'border-color': itemColor, 'border-width': '3px' }">
                            <label class="form-check-label" for="inlineRadio1"></label>
                        </div>
                    </div>
                    <div v-if="datUpdateStatus.errorText != ''">
                        <p class="mt-2 mb-0 text-danger">{{ datUpdateStatus.errorText }}</p>
                    </div>
                </form>
            </div>
            <div class="modal-footer p-1">
                <button type="button" class="btn btn-outline-secondary btn-sm me-1"
                    data-bs-dismiss="modal">{{ $t("UI.close") }}</button>
                <button type="button" class="btn btn-secondary btn-sm" @click="updateStatus(0)">{{ modalBtnOk
                    }}</button>
            </div>
        </template>
    </BsModal>

    <TskModal 
        :modalID='modalIDTsk' 
        :statusName="datUpdateTask.statusName"
        :projectInfo="prInfo"
        :taskData="this.datUpdateTask"
        :projectUsers="this.projectUsers"
        :colors="this.colors"
        modalPosition="right" 
        modalSize="modal-lg cobalt-modal-lg"
        ref="TskModal" 
        @closeModal="closeTaskModal"
        @refresh="updTstts">
    </TskModal>

    <DelModal :modalID='modalIDel'  modalPosition="center" ref="DelModal" class="cobalt-base-dialog">
        <template v-slot:header-left>{{ deleteType.title }}</template>
        <template v-slot:body>
            <div class="modal-body">
                <h2>{{ $t("UI.confirmation") }}</h2>
            </div>
            <div class="modal-footer ps-0 pe-0">
                <button type="button" class="btn btn-outline-secondary btn-sm me-1"
                    data-bs-dismiss="modal">{{ $t("UI.close") }}</button>
                <button type="button" class="btn btn-danger btn-sm" @click="deleteAll">{{ $t("UI.delete") }}</button>
            </div>
        </template>
    </DelModal>
    <ArchModal :modalID='modalIArch'  modalPosition="center" ref="ArchModal" class="cobalt-base-dialog">
        <template v-slot:header-left>{{ archiveType.title }}</template>
        <template v-slot:body>
            <div class="modal-body">
                <h2>{{ $t("UI.confirmation") }}</h2>
            </div>
            <div class="modal-footer ps-0 pe-0">
                <button type="button" class="btn btn-outline-secondary btn-sm me-1"
                    data-bs-dismiss="modal">{{ $t("UI.close") }}</button>
                <button type="button" class="btn btn-danger btn-sm" @click="archiveAll">{{ $t("UI.task.archive") }}</button>
            </div>
        </template>
    </ArchModal>  
    <MoveToModal :modalID='modalIMoveTo' modalSize="modal-sm" modalPosition="center" ref="MoveToModal" class="cobalt-base-dialog">
        <template v-slot:header-left>{{ $t("UI.task.moveTo") }}</template>
        <template v-slot:body>
            <div class="modal-body">
                <p>{{ $t("UI.task.moveToConfirmation") }}</p>
                <ul class="cobalt-statuses__list">
                    <li class="cobalt-statuses__list-item" v-for="elemStat in listStat" :key="elemStat.id" @click="moveTo(elemStat.id)">
                        <span class="cobalt-bullet" :style="{ 'background-color': elemStat.color != '#000000' ? elemStat.color : null }"></span>
                        <span class="title">
                            {{ elemStat.title }} <sup class="tasks-count" v-if="tasks[elemStat.id] && tasks[elemStat.id].length > 0">{{`0${tasks[elemStat.id].length}`.slice(-2)}}</sup>
                        </span>
                    </li>
                </ul>
            </div>
            <div class="modal-footer ps-0 pe-0">
                <button type="button" class="btn btn-outline-secondary btn-sm"
                    data-bs-dismiss="modal">{{ $t("UI.close") }}
                </button>
            </div>
        </template>
    </MoveToModal>    
    <ShowEventTasks :modalID='modalIShowEvent' modalSize="modal-md" modalPosition="center" ref="ShowEventTasks" class="cobalt-base-dialog">
        <template v-slot:header-left>         
            {{ $t("UI.calendar.eventTitle") }}
        </template>
        <template v-slot:body>
            <div class="modal-body">
                <p>{{ $t("UI.calendar.eventTaskConfirmation") }}</p>
                
                <div class="cobalt-statuses__list cobalt-eventTasks__list">
                    <li class="cobalt-statuses__list-item" v-for="eTask in this.eventTasks" :key="eTask.task.id" @click="this.showEventModTask(eTask.task.id, eTask.statusId)">
                        <TaskCardEvent
                            :task="getPreparedTask(eTask.task)"
                            :statusId="eTask.statusId"
                            @showModTask="showEventModTask"
                        />
                    </li>                    
                </div>
            </div>
            <div class="modal-footer ps-0 pe-0">
                <button type="button" class="btn btn-outline-secondary btn-sm"
                    data-bs-dismiss="modal">{{ $t("UI.close") }}
                </button>
            </div>
        </template>
    </ShowEventTasks>        
    <RoleModal :modalID='modalRole.id' modalSize="modal-md" modalPosition="center" ref="RoleModal" class="cobalt-base-dialog">
        <template v-slot:header-left>
            <div class="d-dlex">
                <h2 class="mb-0">{{ modalRole.title }}</h2>
            </div>
        </template>
        <template v-slot:body>
            <div class="modal-body">
                <div v-if="prInfo != null">
                    <RoleProject v-if="modalRole.isProject" :info='prInfo'></RoleProject>
                    <RoleStatus v-else :info='prInfo'></RoleStatus>
                </div>
            </div>
            <div class="modal-footer ps-0 pe-0">
                <button type="button" class="btn btn-outline-secondary btn-sm"
                    data-bs-dismiss="modal">{{ $t("UI.close") }}</button>
            </div>
        </template>
    </RoleModal>  
</template>

<script>
import Navbar from "@/views/board/Navbar.vue"
import BsModal   from "@/components/modals/BsModal.vue"
import DelModal  from "@/components/modals/BsModal.vue"
import ArchModal from "@/components/modals/BsModal.vue"
import MoveToModal from "@/components/modals/BsModal.vue"
import ShowEventTasks from "@/components/modals/BsModal.vue"
import RoleModal from "@/components/modals/BsModal.vue"
import TskModal  from "@/components/modals/TaskModal.vue"
import TaskCard  from "@/components/TaskCard.vue" 
import TaskCardEvent  from "@/components/TaskCardEvent.vue" 
import CobaltDashboard from "@/components/dashboard/CobaltDashboard.vue"
import CobaltProfile   from "@/components/dashboard/parts/CobaltProfile.vue"
import CobaltPerformer from "@/components/dashboard/parts/CobaltPerformer.vue"
import CobaltPerformersList from "@/components/dashboard/parts/CobaltPerformersList.vue"
import RoleProject from "@/views/user/RoleProject.vue"
import RoleStatus  from "@/views/user/RoleStatus.vue"
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import Multiselect from 'vue-multiselect'
import { ref } from 'vue'
import { VueDraggable } from 'vue-draggable-plus'
import { mapGetters, mapActions } from 'vuex'
import { localeDate, priorityOptions, eventDate, nameColor } from '@/utils/format'
import { Qalendar } from "qalendar";
import "qalendar/dist/style.css";

export default {
    components: {
        Navbar,
        BsModal,
        DelModal,
        ArchModal,
        MoveToModal,
        ShowEventTasks,
        TskModal,
        VueDraggable,
        DatePicker,
        Multiselect,
        RoleModal,
        RoleProject,
        RoleStatus,
        TaskCard,
        TaskCardEvent,
        CobaltDashboard,
        CobaltProfile,
        CobaltPerformer,
        CobaltPerformersList,
        Qalendar
    },
    data() {
        return {
            dashboardMode: 'canban',
            calendarKey: 0,
            pojctInfo: null,
            prInfo: {
                id: 0,
                title: '',
                activeUser: null
            },
            statusInfo: null,
            listStat: [],
            modalID: 'modalNewStatus',
            modalIDel: 'ModDelete',
            modalIArch: 'ModArchive',
            modalIDTsk: 'modalIDTsk',
            modalIMoveTo: 'ModMoveTo',
            modalIShowEvent: 'ShowEventTasks',
            menuTop: {
                user: false,
                task: true,
                roles: true
            },
            projectUsers: [],
            datUpdateStatus: {
                id: 0,
                title: '',
                description: '',
                project_id: '',
                weight: 0,
                color: '#000000',
                errorText: ''
            },
            datUpdateTask: {
                statusName: '',
                id: 0,
                title: '',
                description: '',
                status_id: '',
                position: '',
                weight: 0,
                color: '#000000',
                errorText: '',
                date_create: 0,
                date_planned_end: '',
                date_planned_start: '',
                author: {},
                performer: [],
                files: [],
                priority: 0,
                imageFile: ''
            },    
            modalRole: {
                id: 'modalRoleLis',
                size: 'modal-xl',
                title: this.$t("UI.projectRoles"),
                isProject: true
            },        
            lSat: [],
            tasks: [],
            files: [],
            modalTitle: '',
            modalBtnOk: '',
            colors: [ '#E1BEE7', '#C5CAE9', '#BBDEFB', '#C8E6C9', '#FFF9C4', '#FFE0B2', '#FFCDD2', '#000000' ],
            archiveType: {
                type: 0,
                id: 0,
                title: ''
            },
            deleteType: {
                type: 0,
                id: 0,
                title: ''
            },
            taskToMoveID: 0,
            eventStatusId: 0,
            eventTasks: []
        }
    },
    beforeMount: function () {
        this.getInfoComp()
        this.loadUsers()
    }, 
    computed: {
        calendarConfig() {
            return {
                locale: this.$i18n.locale,
                defaultMode: 'month',
            }
        },        
        priorityValues() {
            return [
                { value: 0, bg: "#ebebeb", color: "#262626", label: this.$t('UI.task.priority_0') },
                { value: 1, bg: "#FCEBE6", color: "#9B2406", label: this.$t('UI.task.priority_1') },
                { value: 2, bg: "#fafadb", color: "#816403", label: this.$t('UI.task.priority_2') },
                { value: 3, bg: "#dbfae3", color: "#03590b", label: this.$t('UI.task.priority_3') },
                { value: 4, bg: "#d4edfc", color: "#0e4f9a", label: this.$t('UI.task.priority_4') }
            ];
        },               
        selectedPriority: {
            get() {
                return this.priorityValues.find(option => option.value === this.datUpdateTask.priority) || null
            },
            set(option) {
                this.datUpdateTask.priority = option ? option.value : null
            }
        },
        calendarEvents: {
            get() {
                let minDate = null;
                let maxDate = null;

                // Шаг 1: Определение минимальной и максимальной даты среди всех задач
                for (const statusItem of Object.values(this.tasks)) {
                    statusItem.forEach(taskItem => {
                        if (taskItem.date_planned_start) {
                            const start = new Date(taskItem.date_planned_start * 1000);
                            start.setHours(0, 0, 0, 0); // Начало дня
                            minDate = minDate ? (start < minDate ? start : minDate) : start;
                        }
                        if (taskItem.date_planned_end) {
                            const end = new Date(taskItem.date_planned_end * 1000);
                            end.setHours(23, 59, 59, 59); // Начало дня
                            maxDate = maxDate ? (end > maxDate ? end : maxDate) : end;
                        }
                    });
                }

                // Если задачи нет или все даты пусты, возвращаем пустой массив
                if (!minDate || !maxDate) return [];

                // Шаг 2: Генерация всех дней между minDate и maxDate
                let days = [];
                for (let date = new Date(minDate); date <= maxDate; date.setDate(date.getDate() + 1)) {
                    days.push(new Date(date));
                }

                // Шаг 3: Создание событий для каждого дня и приоритета
                let events = [];
                for (const day of days) {
                    for (let priority = 0; priority < 5; priority++) {
                        // Создаем событие для каждого приоритета в каждом дне
                        events.push({
                            title: `Tasks with priority ${priority} on ${day.toISOString().split('T')[0]}`,
                            with: '',
                            time: { start: new Date(day.setHours(0, 0, 0, 0)), end: new Date(day.setHours(23, 59, 59, 999)) },
                            tasks: {}, // Массив задач с текущим приоритетом
                            color: this.priorityValues.find(option => option.value === priority),
                            priority: priority,
                            isEditable: false,
                            id: `day_${day.toISOString().split('T')[0]}_priority_${priority}`,
                            description: ''
                        });
                    }
                }

                // Шаг 4: Заполнение событий задачами по приоритетам
                for (const [statusId, statusItem] of Object.entries(this.tasks)) {
                    statusItem.forEach(taskItem => {
                        if (taskItem.date_planned_start && taskItem.date_planned_end) {
                            const taskStart = new Date(taskItem.date_planned_start * 1000);
                            taskStart.setHours(0, 0, 0, 0); // Начало дня
                            const taskEnd = new Date(taskItem.date_planned_end * 1000);
                            taskEnd.setHours(23, 59, 59, 999); // Конец дня
                            const priority = taskItem.priority;

                            // Добавляем задачу в события, которые попадают в диапазон её дат и совпадают по приоритету
                            for (const event of events) {
                                // Проверяем, что событие соответствует приоритету задачи и находится в её временном диапазоне
                                if (event.time.start >= taskStart && event.time.end <= taskEnd && event.priority == taskItem.priority) {
                                    if (!event.tasks[statusId])
                                        event.tasks[statusId] = []
                                    event.tasks[statusId].push(taskItem);
                                }
                            };
                        }
                    });
                }

                events.forEach(event => {
                    event.time.start = eventDate(event.time.start.getTime() / 1000)
                    event.time.end = eventDate(event.time.end.getTime() / 1000)
                })
                return events.filter(function( event ) { return Object.keys(event.tasks).length > 0 })

            }
        },
        todayTasks: {
            get() {
                let taskArray = []
                let today = new Date().getTime() / 1000
                
                for (var key in this.tasks) {
                    this.tasks[key].forEach(task => {

                    if ( !task.date_planned_start ) {
                        if (task.date_planned_end) {
                            if (today < task.date_planned_end ) {
                                taskArray.push({'task': task, 'statusId': key })
                            }
                        } else {
                            taskArray.push({'task': task, 'statusId': key })
                        }
                    } else 
                        if (!task.date_planned_end) {
                            if (task.date_planned_start < today) {
                                taskArray.push({'task': task, 'statusId': key })
                            }
                        } else {
                            if (task.date_planned_start < today && task.date_planned_end > today) {
                                taskArray.push({'task': task, 'statusId': key })
                            }
                        }
                    })
                }
                return taskArray
            }
        }
    }, 
    watch: {
        '$i18n.locale'() {
            this.calendarKey += 1;
        }
    },     
    methods: {
        ...mapActions('project', ['info', 'delete', 'listUsers']),
        ...mapGetters('project', ['getInfo', 'getListUsers']),
        ...mapActions('board', ['list', 'updatSt', 'delSt', 'updatTsk', 'updatTskDate', 'clearTskDate', 'delTsk', 'archTsk', 'listPerformers', 'assignPerformer', 'detachPerformer', 'uploadFiles', 'deleteFile', 'sortAll']),
        ...mapGetters('board', ['getList']),  
        ...mapActions('user', ['userInfo']),
        localeDate, 
        nameColor,
        switchDashboardMode(mode) {
            this.dashboardMode = mode
        },
        disabledBeforeToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);

            return date < today;
        },        
        async closeTaskModal() {
            this.$refs.TskModal.hide()
            console.log('***** TASK MODAL CLOSED!!! *****')
        },  
        createNewTask() {
            return {
                statusName: '',
                id: 0,
                title: '',
                description: '',
                status_id: '',
                position: '',
                weight: 0,
                color: '#000000',
                errorText: '',
                date_create: 0,
                date_planned_end: '',
                date_planned_start: '',
                author: {},
                performer: [],
                files: [],
                priority: 0,
                imageFile: ''
            }
        },
        removePerformer(option, id) {
            const updatedPerformers = this.datUpdateTask.performer.filter(item => item.id !== option.id)
            this.datUpdateTask.performer = updatedPerformers
            this.updateTaskPerformer(false, false, option)
        },
        getPreparedTask(task) {
            const priorityObj = this.priorityValues.find(option => option.value === task.priority);
            return {
                ...task,
                priorityObj
            };
        },        
        async getInfoComp() {
            await this.info(this.$route.params.id)
            this.pojctInfo = this.getInfo()

            this.prInfo.title = this.pojctInfo.name
            this.prInfo.id = this.$route.params.id

            this.updTstts()
            this.datUpdateStatus.project_id = this.$route.params.id
            console.log(this.$route.params.id)
        },
        async loadUsers() {
           await this.listUsers(this.$route.params.id)
           this.projectUsers = this.getListUsers()
           this.prInfo.activeUser = await this.userInfo()
        },
        showMod(id) {
            //this.datUpdateStatus.id = id
            if (id > 0) {
                this.modalTitle = this.$t("UI.changeStatus")
                this.modalBtnOk = this.$t("UI.change")
                this.listStat.forEach(element => {
                    if (id == element.id) {
                        this.datUpdateStatus = {... element}
                    }
                });
            } else {
                this.modalTitle = this.$t("UI.addStatus")
                this.modalBtnOk = this.$t("UI.add")
                this.datUpdateStatus = {... {
                    id: 0, title: '', description: '', project_id: this.$route.params.id, weight: 0, color: '#000000'
                } }
            }

            this.$refs.BsModal.shows();
        },
        showModTask(id, status) {
            //this.datUpdateTask.id = id
            
            if (id > 0) {
                this.modalTitle = this.$t("UI.task.change")
                this.modalBtnOk = this.$t("UI.change")

                this.tasks[status].forEach(elementT => {
                    if (id == elementT.id) {
                        this.datUpdateTask = {... elementT}
                    }
                })
            } else {
                this.modalTitle = this.$t("UI.addTask")
                this.modalBtnOk = this.$t("UI.add")

                this.datUpdateTask = this.createNewTask()
            }
            
            this.listStat.forEach(elementS => {
                if (status == elementS.id) {
                    this.datUpdateTask.statusName = elementS.title
                    this.datUpdateTask.status_id  = elementS.id
                }
            })

            this.$refs.TskModal.shows()
        },       
        showDel(type, id) {
            this.deleteType.type = type
            this.deleteType.id = id

            if (type == 1) {
                this.deleteType.title = this.$t("UI.deleteCompany")
            } else if (type == 2) {
                this.deleteType.title = this.$t("UI.deleteStatus")
            } else if (type == 3) {
                this.deleteType.title = this.$t("UI.task.delete")
            }

            this.$refs.DelModal.shows()
        },
        showArch(type, id) {
            this.archiveType.type = type
            this.archiveType.id = id

            if (type == 1) {
                this.archiveType.title = this.$t("UI.archiveCompany")
            } else if (type == 2) {
                this.archiveType.title = this.$t("UI.archiveStatus")
            } else if (type == 3) {
                this.archiveType.title = this.$t("UI.task.archive")
            }

            this.$refs.ArchModal.shows()
        },
        showMoveTo(taskId) {
            this.taskToMoveID = taskId
            this.$refs.MoveToModal.shows()
        },   
        showModEvent(tasks) {
            this.eventTasks = []

            for (const [statusId, taskArray] of Object.entries(tasks)) {
                for (const task of taskArray) {
                    this.eventTasks.push({ statusId: statusId, task: task})
                }
            }

            if (this.eventTasks.length == 0) return

            if ( this.eventTasks.length > 1 ) {
                this.$refs.ShowEventTasks.shows()
            } else {
                this.showModTask( this.eventTasks[0].task.id, this.eventTasks[0].statusId)
            }
        },   
        showEventModTask(taskId, statusId) {
            this.$refs.ShowEventTasks.hide()
            this.showModTask(taskId, statusId)
        },                         
        async updTstts() {
            await this.list(this.pojctInfo.id)
            let allStatus = this.getList()
            this.tasks = {};
            this.listStat = [];

            if (allStatus.length > 0) {
                let indx = 0
                allStatus.forEach(element => {
                    let ts = [];
                    let indxT = 0;
                    element.tasks.forEach(elementTs => {
                        ts[indxT] = elementTs
                        indxT++

                        if (this.datUpdateTask.id == elementTs.id) {
                            this.datUpdateTask = elementTs
                        }                        
                    });

                    this.tasks[element.id] = ts

                    this.listStat[indx] = element
                    indx++
                })
            }
        },
        chColor(val) {
            this.datUpdateStatus.color = val
        },
        chColorTsk(val) {
            this.datUpdateTask.color = val
        },               
        updateParagraph(text) {
            console.log(text)
        },
        async deleteProject() {
            await this.delete(this.pojctInfo.id)
            this.$refs.DelModal.hide()
            this.$router.push({ name: 'home' })

        },
        async deleteStatus() {
            await this.delSt(this.deleteType.id)

            this.updTstts()
            this.$refs.DelModal.hide()
        },
        async deleteTask() {
            await this.delTsk(this.deleteType.id)

            this.updTstts()
            this.$refs.DelModal.hide()
        },
        async archiveTask() {
            await this.archTsk(this.archiveType.id)

            this.updTstts()
            this.$refs.ArchModal.hide()            
        },
        async updateStatus() {
            await this.updatSt(this.datUpdateStatus)

            this.updTstts()
            this.$refs.BsModal.hide()
        },     
        updateCurrentTaskData() {
            //
        },    
        archiveAll() {
            if (this.archiveType.type == 1) {
                this.archiveProject()
            } else if (this.archiveType.type == 2) {
                this.archiveStatus()
            } else if (this.archiveType.type == 3) {
                this.archiveTask()
            }
        },
        deleteAll() {
            if (this.deleteType.type == 1) {
                this.deleteProject()
            } else if (this.deleteType.type == 2) {
                this.deleteStatus()
            } else if (this.deleteType.type == 3) {
                this.deleteTask()
            }
        },
        moveTo(statusId) {
            let arr = [];
            let indx = 0;

            this.listStat.forEach(element => {
                let arrTsk = []
                let indxTsk = 0
                if (statusId == element.id) {
                    arrTsk[indxTsk] = this.taskToMoveID
                    indxTsk++
                }
                this.tasks[element.id].forEach(elementTsk => {
                    if (this.taskToMoveID != elementTsk.id) {
                        arrTsk[indxTsk] = elementTsk.id
                        indxTsk++
                    }
                })
                arr[indx] = { 'id': element.id, 'task': arrTsk }
                indx++
            })

            this.taskToMoveID = 0
            let jsonStat = JSON.stringify(arr)
            this.sortAll(jsonStat)

            this.updTstts()
            this.$refs.MoveToModal.hide()            
        },     
        onEnd(e) {
            // console.log(this.tasks)
            let arr = [];
            let indx = 0;
            this.listStat.forEach(element => {
                let arrTsk = []
                let indxTsk = 0
                this.tasks[element.id].forEach(elementTsk => {
                    arrTsk[indxTsk] = elementTsk.id
                    indxTsk++
                });
                arr[indx] = { 'id': element.id, 'task': arrTsk }

                indx++
            });

            let jsonStat = JSON.stringify(arr)
            this.sortAll(jsonStat)
        },
        showRole() {
            this.modalRole.isProject = true
            this.modalRole.title = this.$t("UI.projectRoles")
            this.$refs.RoleModal.shows()
        },
        showModStatRole() {
            this.modalRole.isProject = false
            this.modalRole.title = this.$t("UI.statusRoles")
            this.$refs.RoleModal.shows()
        },           
        calendarEventClick(e) {
            console.log(e)
        }
    }

}
</script>

<style>
    .over-bg {
        background-color: rgba(0, 58, 92, 0.616);
    }

    .btn svg {
        fill: #747373;
    }
</style>