<template>
    <div class="d-lg-flex half">
      <div 
        class="bg order-1 order-md-2 auth-bg d-none d-lg-block" 
        :style="{'background-image': 'url(' + require('@/assets/images/auth/' + $i18n.locale +  '/welcome.svg') + ')'}">
      </div>
      <div class="contents order-2 order-md-1">
            <div class="container">
                <div class="row align-items-center justify-content-center">
                    <div class="col-md-7 text-center">              

                        <div class="cobalt-auth mb-4 text-lg-left" v-if="!code">
                            <h1 style="color: #FF0000;">404</h1>
                            <p>{{ $t('error.error404') }}</p>
                        </div>

                        <div v-if="code">
                            <div class="cobalt-auth mb-4 text-lg-left">
                                <img :src="require('@/assets/images/auth/' + $i18n.locale +  '/invite.svg')" :alt="$t('auth.registration')" />
                                <p class="mt-4">
                                    Ваc пригласили принять участие в проекте в системе Сobalt.
                                </p>
                            </div>

                            <div class="cobalt-auth mb-4 text-lg-left">

                                <button type="button" class="btn cobalt-btn-primary btn-lg flex-fill mb-4" @click="tryToJoin" :class="{ 'disabled' : isLoading }" v-if="loggedIn">
                                    <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true" v-if="isLoading"></span>
                                    Присоединиться
                                </button>

                                <p v-if="!loggedIn">
                                    Вам нужно <a href="../register">зарегистрироваться</a>, если Вы еще не зарегистрированы в системе Сobalt<br />
                                    Или <a href="../login">войти</a>, если у Вас уже есть учетная запись.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            isLoading: false,
            loggedIn: false  // Новая переменная для состояния логина
        }
    },
    computed: {
        code() {
            return this.$route.query.code || null;
        },
        from() {
            return this.$route.query.from || null;
        } 
    },
    methods: {
        ...mapActions('user', ['isLoggedIn']),
        ...mapActions('company', ['sendInvitation']),

        async tryToJoin() {
            this.isLoading = true;
            let data = {
                'code': this.code
            };

            let result = await this.sendInvitation(data);

            if (result) {
                this.$router.push('/');
            } else {
                this.$router.push('/invite');
            }
            this.isLoading = false;
        },
        async checkUserLoggedIn() {  // Переименован и изменен метод
            this.loggedIn = await this.isLoggedIn();  // Обновляем состояние залогинен ли пользователь
        }
    },
    created() {
        this.checkUserLoggedIn();  // Проверка логина при создании компонента
    }
}
</script>
