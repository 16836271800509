<template>
    <form class="cobalt-task-form" @submit.prevent="updateTask()">
        <div class="mb-4">
            <span class="cobalt-task-form__date ps-0" v-if="taskData.date_create">
            {{ $t("UI.task.taskCreatedAt") }} {{ localeDateTime(taskData.date_create) }} {{ $t("UI.task.taskUpdatedAt") }} {{ localeDateTime(taskData.date_update) }}
            </span>
        </div>                  
        <div class="mb-2">
            <input type="text" class="form-control form-control-plaintext has-text-weight-bold" id="taskTitle" autocomplete="off" 
                v-model="taskData.title" :placeholder="$t('UI.task.name')">                        
            <textarea class="form-control form-control-plaintext" id="taskDescription" autocomplete="off" 
                v-model="taskData.description" :placeholder="$t('UI.task.description')"></textarea>
        </div>
        <div class="mb-4">
            <CoverUploader
                :initialImage="taskData.imageFile"
                :updateCover="updateCoverImage"
                :removeCover="removeCoverImage"
                @file-selected="handleCoverSelected"
            />
        </div>
        <div class="mb-4">
            <ul class="nav nav-tabs cobalt-task__nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link" :class="{ active: activeTab === 'params' }" @click="setActiveTab('params')" id="files-tab" data-bs-toggle="tab" data-bs-target="#params" type="button" role="tab" aria-controls="home" aria-selected="true">
                        {{ $t("UI.task.params") }}
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button class="nav-link" :class="{ active: activeTab === 'files' }" @click="setActiveTab('files')" id="files-tab" data-bs-toggle="tab" data-bs-target="#files" type="button" role="tab" aria-controls="home" aria-selected="true">
                        <img src="@/assets/images/dashboard/task-files.svg"> {{ $t("UI.task.files") }}
                        <span v-if="this.taskData.files.length > 0" class="badge cobalt-badge__simple ms-1">{{ this.taskData.files.length }}</span>
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button class="nav-link" :class="{ active: activeTab === 'comments' }" @click="setActiveTab('comments')" id="comments-tab" data-bs-toggle="tab" data-bs-target="#comments" type="button" role="tab" aria-controls="home" aria-selected="true">
                        <img src="@/assets/images/dashboard/task-comment.svg"> {{ $t("UI.task.comments") }}
                        <span v-if="this.taskData.comments?.length > 0" class="badge cobalt-badge__simple ms-1">{{ this.taskData.comments.length }}</span>
                    </button>
                </li>                
            </ul>
        </div>      

        <div class="position-relative h-100">
            <div class="tab-content cobalt-task__tab-content" id="myTabContent">
                <div class="tab-pane fade show" :class="{ active: activeTab === 'params' }" id="params" role="tabpanel" aria-labelledby="params-tab">
                    <div class="row mb-4">
                        <label class="col-sm-4 cobalt-task-form__label" for="taskAuthor"><img src="@/assets/images/dashboard/task-user.svg"> {{ $t("UI.task.author") }}</label>
                        <span class="cobalt-task-form__plaintext col-sm-8">
                            <CobaltProfile :firstName="taskData.author?.first_name" :lastName="taskData.author?.last_name" />
                        </span>
                    </div>   
                    <div class="row mb-4">
                        <label class="col-sm-4 cobalt-task-form__label" for="taskDate"><img src="@/assets/images/dashboard/task-calendar.svg"> {{ $t("UI.task.date") }}</label>
                        <span class="cobalt-task-form__plaintext col-sm-8">
                            <DatePicker
                                v-model:value="datePlannedStart"
                                :placeholder="this.$t('UI.selectDate')"
                                type="datetime"
                                value-type="timestamp"
                                show-time-header="true"
                                format="DD.MM.YYYY HH:mm"
                            />
                        </span>
                    </div>    
                    <div class="row mb-4">
                        <label class="col-sm-4 cobalt-task-form__label" for="taskDeadline"><img src="@/assets/images/dashboard/task-time.svg"> {{ $t("UI.task.deadline") }}</label>
                        <span class="cobalt-task-form__plaintext col-sm-8">
                            <DatePicker 
                                v-model:value="datePlannedEnd"
                                :placeholder="this.$t('UI.selectDate')"
                                type="datetime"
                                value-type="timestamp"
                                show-time-header="true"
                                format="DD.MM.YYYY HH:mm"    
                                :disabled-date="disabledBeforeStart"                        
                            />                         
                        </span>
                    </div>    
                    <div class="row mb-4">
                        <label class="col-sm-4 cobalt-task-form__label" for="taskPriority"><img src="@/assets/images/dashboard/task-priority.svg"> {{ $t("UI.task.priority") }}</label>
                        <span class="cobalt-task-form__plaintext col-sm-8">
                            <Multiselect 
                                v-model="selectedPriority" 
                                :placeholder="this.$t('UI.select')"
                                selectLabel=""
                                :options="priorityValues" 
                                :searchable="false"
                                track-by="value"
                                label="label"
                                selectedLabel=""
                                deselectLabel=""
                            >
                                <template #singleLabel="props">
                                    <span class="cobalt-task__priority" :style="{ 'background-color': props.option.bg, 'color': props.option.color }">
                                        {{ props.option.label }}
                                    </span>                                    
                                </template>
                                <template #option="props">
                                    <span class="cobalt-task__priority" :style="{ 'background-color': props.option.bg, 'color': props.option.color }">
                                        {{ props.option.label }}
                                    </span>
                                </template>
                            </Multiselect>
                        </span>
                    </div>    
                    <div class="row mb-4 align-items-start" v-if="taskData.id">
                        <label class="col-sm-4 cobalt-task-form__label" for="taskPriority"><img src="@/assets/images/dashboard/task-user.svg"> {{ $t("UI.task.performers") }}</label>
                        <span class="cobalt-task-form__plaintext col-sm-8">
                            <Multiselect 
                                v-model="selectedPerformers" 
                                :placeholder="this.$t('UI.select')"
                                :multiple="true"
                                :options="projectUsers" 
                                :class="['cobalt-task__multiselect']"
                                track-by="id"                              
                                selectLabel=""
                                label="first_name"
                                selectedLabel=""
                                deselectLabel="&#x2715;"
                                @remove="removePerformer"
                            >
                                <template #selection="{ values, search, isOpen }">
                                    <div class="cobalt-profile-list__wrapper">
                                        <div class="cobalt-profile-list__item" v-for="item in values">
                                            <CobaltProfile 
                                                :firstName="item.first_name" 
                                                :lastName="item.last_name" 
                                                :short="true"
                                            />
                                        </div>
                                    </div>
                                </template>                            
                                <template #option="props">
                                    <span class="cobalt-task__performer-dropdown-item">
                                        {{ props.option.first_name + ' ' + props.option.last_name }}
                                    </span>
                                </template>
                            </Multiselect>   
                        </span>
                    </div> 
                    <div class="row mb-4 align-items-start" v-if="taskData.id">
                        <label class="col-sm-4 cobalt-task-form__label" for="taskPriority"><img src="@/assets/images/dashboard/task-tags.svg"> {{ $t("UI.task.tags") }}</label>
                        <span class="cobalt-task-form__plaintext col-sm-8">
                            <multiselect 
                                v-model="taskData.tags" 
                                tag-placeholder="" 
                                :placeholder="$t('UI.task.searchOrAddTag')"
                                selectLabel=""
                                deselectLabel="✖"
                                :options="this.tags" 
                                :showNoOptions="false"
                                :showNoResults="false"
                                :multiple="true" 
                                :taggable="true" 
                                :hide-selected="true"
                                :loading="this.isTagsLoading"
                                :block-keys="['Tab', 'Enter', '']"
                                @search-change="this.doSearchTags"
                                @select="this.doAddTag"
                                @tag="this.doAddTag">

                                <template #tag="{ option, remove }">
                                    <span class="tag custom__tag me-1" :style="{ 'background-color': nameColor(tag) + '55' }">
                                        <span>{{ option }}</span>
                                        <span class="custom__remove ms-1" @click="doRemoveTag(option)"> ✖ </span>
                                    </span>
                                </template>
                                <template #clear="props">
                                    <div class="multiselect__clear" v-if="taskData.tags.length"
                                        @mousedown.prevent.stop="clearAll(props.search)">{{ props.option }}</div>
                                </template>    
                                <template #noOptions>
                                    <span>{{ $t("UI.nothingFound") }}</span>
                                </template>                                                          
                                <template #noResult>
                                    <span>Oops! No elements found. Consider changing the search query.</span>
                                </template>

                            </multiselect>
                        </span>
                    </div>                      
                    <div class="mb-1">
                        <label>{{ $t("UI.backgroundColor") }}</label>
                    </div>
                    <div class="mb-4">
                        <div class="form-check form-check-inline" v-for="itemColor in colors">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                :checked="itemColor == taskData.color" @change="chColorTsk(itemColor)"
                                id="inlineRadio1" :value="itemColor"
                                :style="{ 'border-color': itemColor, 'border-width': '3px' }">
                            <label class="form-check-label" for="inlineRadio1"></label>
                        </div>
                    </div>  
                    
                    <div class="mb-4"  v-if="!taskData.parent_id">
                        <h2 class="cobalt-task__subtitle subtask">
                            {{ $t("UI.task.subTasks") }}
                            <button class="cobalt-task__subtask-add nav-link ms-2" type="button" @click="this.$emit('showModSubtask', 0, taskData.id)">
                                <img src="@/assets/images/dashboard/task-add-2.svg" style="width: 19px;">
                            </button>
                        </h2>
                        <ul class="cobalt-task__subtask-list">
                           <li class="subtask-list__item" v-for="subtask in taskData.subtask" :key="subtask.id">
                                <div class="subtask-list__item-check"><img src="@/assets/images/dashboard/base-checkbox.svg"></div>
                                <div class="subtask-list__item-title">{{ subtask.title }}</div>
                                <div class="subtask-list__item-actions">
                                    <div class="cobalt-task__attachment" v-if="subtask.files?.length > 0">{{ (subtask.files?.length).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) }}</div>
                                    <div class="cobalt-task__comments" v-if="subtask.comments?.length > 0">{{ (subtask.comments?.length).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) }}</div>
                                    <div class="devider" v-if="subtask.files?.length > 0 || subtask.comments?.length > 0">|</div>
                                    <button class="btn btn-base btn-warning me-1" @click="removeSubtask(subtask.id)"><img src="@/assets/images/dashboard/task-remove.svg"></button>
                                    <button class="btn btn-base btn-filled" @click="this.$emit('showModSubtask', subtask.id, taskData.id)"><img src="@/assets/images/dashboard/task-view.svg"></button>
                                </div>
                           </li> 
                        </ul>
                        <div class="cobalt-task__subtask-footer">

                        </div>
                    </div>                      
                </div>

                <div class="tab-pane fade show" :class="{ active: activeTab === 'files' }" id="files" role="tabpanel" aria-labelledby="files-tab">
                    <div class="flex flex-wrap cobalt-uploader">
                        <uploader
                            ref="uploaderRef"
                            :auto="false"
                            @file-added="onFilesAdded"
                            @file-error="onFilesError"
                        >
                            <uploader-drop>
                                <span>{{ $t("UI.task.dropOrSelectFiles") }}</span>
                                <uploader-btn>{{ $t("UI.task.selectFiles") }}</uploader-btn>
                            </uploader-drop>
                            <uploader-list></uploader-list>                                    
                        </uploader>
                        
                        <div class="uploader-list">
                            <div class="uploader-file" v-for="file in this.taskData.files" :key="file.id">
                                <div class="uploader-file-name"><i class="uploader-file-icon" icon="unknown"></i><a target="_blank" :href="Config.SERVER + 'web/' + file.filePath">{{ file.name }}</a></div>
                                <div class="uploader-file-actions">
                                    <a class="btn btn-base me-1" target="_blank" :download="file.filePath" :href="Config.SERVER + 'web/' + file.filePath">
                                        <img src="@/assets/images/dashboard/base-download-dark.svg">
                                    </a>
                                    <div class="devider">|</div>
                                    <button class="btn btn-base btn-warning me-1" @click="onFileDelete(file.id)">
                                        <img src="@/assets/images/dashboard/task-remove.svg">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade show h-100" :class="{ active: activeTab === 'comments' }" id="comments" role="tabpanel" aria-labelledby="comments-tab">
                    <div class="flex flex-wrap cobalt-comments__wrapper">
                        <div class="cobalt-comments__list">
                            <div class="comment-group" v-for="date in sortedDates" :key="date">
                                <h3 class="comment-group__header">{{ date }}</h3>
                                <div class="comment-group__body">
                                    <div class="comment-wrapper" v-for="comment in groupedComments[date]" :key="comment.id">
                                        <div class="comment" :class="{'my-comment' : this.activeUser.id == comment.user_id}">
                                            <div class="comment-content p-2">
                                                <div class="replyToComment" v-if="comment.reply">
                                                    <div class="replyToComment__content">
                                                        <span class="author">{{ comment.reply.user.name }}</span> 
                                                        {{ comment.reply.body }}
                                                    </div>                                                    
                                                </div>
                                                <p class="author">{{ comment.user.name }}</p> 
                                                <p class="small mb-0">
                                                    {{ comment.body }}
                                                </p>
                                            </div>
                                            <span>{{ localeTime(comment.date_create) }}</span>
                                        </div>
                                        <div class="comment-actions">
                                            <button class="btn btn-base me-1" @click="this.replyComment(comment)">
                                                <img src="@/assets/images/dashboard/base-reply.svg">
                                            </button>
                                            <div class="dropdown">
                                                <button class="btn btn-link btn-sm text-body-tertiary" :id="'commentDropdownMenuLink' + comment.id" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="@/assets/images/dashboard/more-vertical.svg">
                                                </button>
                                                <ul class="dropdown-menu" :aria-labelledby="'commentDropdownMenuLink' + comment.id">
                                                    <li class="dropdown-item">
                                                        <button type="button" class="btn btn-link btn-sm me-2 text-body-tertiary" @click="this.replyComment(comment)">
                                                            {{ $t("UI.task.commentReplay") }}
                                                        </button>
                                                    </li>
                                                    <li class="dropdown-item">
                                                        <button type="button" class="btn btn-link btn-sm me-2 text-body-tertiary">
                                                            {{ $t("UI.task.commentEdit") }}
                                                        </button>                                                            
                                                    </li>
                                                    <li class="dropdown-item">
                                                        <button type="button" class="btn btn-link btn-sm me-2 text-body-tertiary" @click="this.deleteComment(comment.id)">
                                                            {{ $t("UI.task.commentDelete") }}
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                        <div class="cobalt-comments__footer">
                            <div class="replyPanel" v-if="replyToComment">
                                <div class="replyToComment">
                                    <div class="replyToComment__content">
                                        <span class="author">{{ replyToComment.user.name}}</span>
                                        {{ replyToComment.body }}
                                    </div>
                                </div>
                                <button type="button" class="cobalt-toolbar__icon-btn" @click="this.replyToComment = null">
                                    <img src="@/assets/images/dashboard/base-close-dark.svg" />
                                </button>
                            </div>
                            <div class="cobalt-comments__input">
                                <textarea v-model="this.comment" ref="commentTextInput" @keypress.enter="sendComment"></textarea>
                            </div>
                            <div class="cobalt-comments__input-actions">
                                <div class="cobalt-comments__input-actions-commands"></div>
                                <button class="btn btn-filled cobalt-task__comment-add" :class="this.comment.length == 0 ? 'disabled' : ''" type="button" @click="sendComment">
                                    {{ $t("UI.task.sendComment") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>                       
    </form>
</template>

<script>
import CobaltProfile   from "@/components/dashboard/parts/CobaltProfile.vue"
import CobaltPerformer from "@/components/dashboard/parts/CobaltPerformer.vue"
import CobaltPerformersList from "@/components/dashboard/parts/CobaltPerformersList.vue"
import CoverUploader from "@/components/CoverUploader.vue";
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import Multiselect from 'vue-multiselect'
import { ref } from 'vue'
import { VueDraggable } from 'vue-draggable-plus'
import { mapGetters, mapActions } from 'vuex'
import { nameColor, localeDate, localeTime, localeDateTime, priorityOptions, eventDate } from '@/utils/format'
import Config from '../../../config.js'

export default {
    components: {
        DatePicker,
        Multiselect,
        CobaltProfile,
        CobaltPerformer,
        CobaltPerformersList,
        CoverUploader
    },    
    props: {
        activeUser: {
            type: Object,
            required: true
        },        
        taskData: {
            type: Object,
            required: true
        },
        projectUsers: {
            type: Array,
            required: true
        },
        colors: {
            type: Array,
            required: true
        },        
        projectInfo: {
            type: Object,
            required: true
        }  
    },    
    data() {
        return {
            Config,
            alwaysFirstTab: true,
            comment: '',
            activeTab: 'params',
            replyToComment: null,
            files: [],
            isTagsLoading: false,
            tags: []
        }
    },
    computed: {
        datePlannedStart: {
            get() {
                return this.taskData.date_planned_start ? this.taskData.date_planned_start * 1000 : null
            },
            set(option) {
                if (option) {
                    this.taskData.date_planned_start = option / 1000
                } else {
                    this.taskData.date_planned_start = null
                }
            }
        },
        datePlannedEnd: {
            get() {
                return this.taskData.date_planned_end ? this.taskData.date_planned_end * 1000: null
            },
            set(option) {
                if (option) {
                    this.taskData.date_planned_end = option / 1000
                } else {
                    this.taskData.date_planned_end = null
                }
            }
        }, 
        priorityValues() {
            return [
                { value: 0, bg: "#ebebeb", color: "#262626", label: this.$t('UI.task.priority_0') },
                { value: 1, bg: "#FCEBE6", color: "#9B2406", label: this.$t('UI.task.priority_1') },
                { value: 2, bg: "#fafadb", color: "#816403", label: this.$t('UI.task.priority_2') },
                { value: 3, bg: "#dbfae3", color: "#03590b", label: this.$t('UI.task.priority_3') },
                { value: 4, bg: "#d4edfc", color: "#0e4f9a", label: this.$t('UI.task.priority_4') }
            ];
        },               
        selectedPriority: {
            get() {
                return this.priorityValues.find(option => option.value === this.taskData.priority) || null
            },
            set(option) {
                this.taskData.priority = option ? option.value : null
            }
        },
        selectedPerformers: {
            get() {
                return this.taskData.performer
            },
            set(option) {
                if (Array.isArray(option) && option.length > 0) {
                    const updatedPerformers = [...option]
                    this.taskData.performer = updatedPerformers
                    this.updateTaskPerformer(true, false, option[option.length - 1])
                }
            }            
        },
        groupedComments() {
            return this.taskData.comments?.reduce((groups, comment) => {
                const date = comment.date_create * 1000;
                const fDate = new Date(date).toLocaleDateString('ru-RU')

                if (!groups[fDate]) {
                    groups[fDate] = []
                }
                groups[fDate].push(comment)
                return groups
            }, {})
        },
        selectedTags: {
            get() {
                return this.taskData.tags
            },
            set(option) {
                if (Array.isArray(option) && option.length > 0) {
                    const updatedTags = [...option]
                    this.taskData.tags = updatedTags
                }
            }   
        },        
        sortedDates() {
            if (!this.groupedComments) {
                return []
            }
            return Object.keys(this.groupedComments).sort((a, b) => a - b).reverse()//new Date(b).getTime() - new Date(a).getTime()) //.reverse()
        }         
    },
  
    methods: {
        ...mapActions('board', ['updatTsk', 'updatTskDate', 'clearTskDate', 'listPerformers', 'assignPerformer', 'detachPerformer', 'uploadFiles', 'deleteFile', 'delSubTsk', 'addTaskComment', 'deleteTaskComment', 'searchTags', 'addTag', 'removeTag', 'uploadCover', 'removeCover']),
        localeDate,
        localeTime,
        localeDateTime,
        nameColor,
        initialize() {
            this.replyToComment = null
            this.activeTab = 'params'
            this.$refs.uploaderRef.fileList = []
        },       
        disabledBeforeStart(date) {
            const start = new Date(this.taskData.date_planned_start * 1000);
            return date < start;
        },          
        chColorTsk(val) {
            this.taskData.color = val
        },          
        async updateTask() {
            let updateResult = await this.updatTsk(this.taskData)
            console.log(this.taskData)
            
            await this.updTstts()

            return updateResult
        },
        async updateTaskPerformer(operation, subtask, performer) {
            if (operation) {
                await this.assignPerformer({
                    task_id: this.taskData.id,
                    subtask: subtask,
                    performer_id: performer.id,
                })
            } else {
                await this.detachPerformer({
                    task_id: this.taskData.id,
                    subtask: subtask,
                    performer_id: performer.id,
                })                
            }
            this.updTstts()
        },
        removePerformer(option, id) {
            const updatedPerformers = this.taskData.performer.filter(item => item.id !== option.id)
            this.taskData.performer = updatedPerformers
            this.updateTaskPerformer(false, false, option)
        },       
        removeSubtask(id) {
            const updatedSubtasks = this.taskData.subtask.filter(item => item.id !== id)
            this.taskData.subtask = updatedSubtasks
            let result = this.delSubTsk(id)

            this.updTstts()
        },
        async updateTaskDate() {
            if (!this.taskData.id) {
                await this.updateTask();
            }
            await this.updatTskDate(this.taskData)
            this.updTstts()
        },
        async clearTaskDate() {
            await this.clearTskDate(this.taskData)
            this.updTstts()
        },   
        onFilesAdded(files) {
            this.files = this.files.concat(files);
            this.doUploadFiles();
        },  
        onFileDelete(id) {
            this.doDeleteFile(id)

            this.taskData.files = this.taskData.files.filter(function( obj ) {
                return obj.id !== id;
            });            

            this.updTstts()
        },  
        async doDeleteFile(id) {
            try {
                const response = this.deleteFile(id)

                if (response) {
                    console.log('File remove successfully:', response);
                } else {
                    console.error('File remove failed:', response);
                }
            } catch (error) {
                console.error('Error removing files:', error);
            }
        },         
        async doUploadFiles() {
            if (this.files.length === 0) {
                console.log('No files to upload.');
                return;
            }

            const formData = new FormData();

            this.files.forEach((file, index) => {
                formData.append(`files[${index}]`, file.file);
            });

            formData.append('id', this.taskData.id);

            try {
                const response = this.uploadFiles(formData)

                if (response) {
                    console.log('Files uploaded successfully:', response);
                } else {
                    console.error('File upload failed:', response);
                }
            } catch (error) {
                console.error('Error uploading files:', error);
            }

            this.files = [];
        },
        onFilesError(file, error) {
            console.error('File upload failed:', file, error);
        }, 
        async sendComment() {
            if (this.comment.length == 0) {
                return
            }

            let commentData = {
                'body': this.comment,
                'task_id': this.taskData.id,
                'isSubtask': false
            }

            if (this.replyToComment) {
                commentData['replyTo'] = this.replyToComment.id
            }

            let result = await this.addTaskComment(commentData)

            this.replyToComment = null

            if (result) {
                this.comment = ''
            }

            this.updTstts()
        }, 
        async deleteComment(id) {
            let commentData = {
                'id': id,
                'isSubtask': false
            }

            let result = await this.deleteTaskComment(commentData)
            this.updTstts()
        },
        replyComment(comment) {
            this.replyToComment = comment
            this.$refs.commentTextInput.focus()
        },
        setActiveTab(tab) {
          this.activeTab = tab
        },     
        async doSearchTags(search) {   
            if (!search) return

            this.isLoading = true
            const searchData = {
                name: search,
                projectId: this.projectInfo.id
            }        
            let response = await this.searchTags(searchData)
            this.isTagsLoading = false
            this.tags = response ? response : []
        }, 
        async doSelectTag(tag) {
            this.tags.push(tag)
            this.taskData.tags = this.tags;            
        },
        async doAddTag(tag) {
            this.isLoading = true
            const tagData = {
                name: tag,
                task_id: this.taskData.id,
                isSubtask: false
            }
            this.tags = await this.addTag(tagData)
            this.isTagsLoading = false

            this.taskData.tags = this.tags;
        },     
        async doRemoveTag(tag) {
            this.isLoading = true
            const tagData = {
                name: tag,
                task_id: this.taskData.id,
                isSubtask: false
            }
            this.tags = await this.removeTag(tagData)
            this.isTagsLoading = false

            this.taskData.tags = this.tags;
        },
        handleCoverSelected(file) {
            console.log("Выбранный файл:", file)
        },
        async updateCoverImage(file) {
            const formData = new FormData()
            formData.append("image", file)
            formData.append("id", this.taskData.id)

            let result = await this.uploadCover(formData)
            this.updTstts()
        },
        async removeCoverImage() {
            let result = await this.removeCover(this.taskData.id)
            this.updTstts()
        },        
        updTstts() {
            this.$emit('refresh')
        }
    }
}
</script>

<style></style>