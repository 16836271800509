<template>
    <form class="cobalt-task-form" @submit.prevent="updateSubTask()">
        <div class="mb-4">
            <span class="cobalt-task-form__date ps-0" v-if="taskData.date_create">
            {{ $t("UI.task.taskCreatedAt") }} {{ localeDateTime(taskData.date_create) }} {{ $t("UI.task.taskUpdatedAt") }} {{ localeDateTime(taskData.date_update) }}
            </span>
        </div>                  
        <div class="mb-4">
            <input type="text" class="form-control form-control-plaintext has-text-weight-bold" id="taskTitle" autocomplete="off" 
                v-model="taskData.title" :placeholder="$t('UI.task.name')">                        
            <textarea class="form-control form-control-plaintext" id="taskDescription" autocomplete="off" 
                v-model="taskData.description" :placeholder="$t('UI.task.description')"></textarea>
        </div>
        <div class="mb-4">
            <ul class="nav nav-tabs cobalt-task__nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link" :class="{ active: activeTab === 'params' }" @click="setActiveTab('params')" id="st-params-tab" data-bs-toggle="tab" data-bs-target="#st-params" type="button" role="tab" aria-controls="home" aria-selected="true">
                        {{ $t("UI.task.params") }}
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button class="nav-link" :class="{ active: activeTab === 'files' }" @click="setActiveTab('files')" id="st-files-tab" data-bs-toggle="tab" data-bs-target="#st-files" type="button" role="tab" aria-controls="home" aria-selected="true">
                        <img src="@/assets/images/dashboard/task-files.svg"> {{ $t("UI.task.files") }}
                        <span v-if="this.taskData.files.length > 0" class="badge cobalt-badge__simple ms-1">{{ this.taskData.files.length }}</span>
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button class="nav-link" :class="{ active: activeTab === 'comments' }" @click="setActiveTab('comments')" id="st-comments-tab" data-bs-toggle="tab" data-bs-target="#st-comments" type="button" role="tab" aria-controls="home" aria-selected="true">
                        <img src="@/assets/images/dashboard/task-comment.svg"> {{ $t("UI.task.comments") }}
                        <span v-if="this.taskData.comments?.length > 0" class="badge cobalt-badge__simple ms-1">{{ this.taskData.comments.length }}</span>
                    </button>
                </li>                
            </ul>
        </div>      

        <div class="mb-4 h-100 position-relative">
            <div class="tab-content cobalt-task__tab-content" id="myTabContent">
                <div class="tab-pane fade show" :class="{ active: activeTab === 'params' }" id="st-params" role="tabpanel" aria-labelledby="st-params-tab">
                    <div class="row mb-4">
                        <label class="col-sm-4 cobalt-task-form__label" for="taskAuthor"><img src="@/assets/images/dashboard/task-user.svg"> {{ $t("UI.task.author") }}</label>
                        <span class="cobalt-task-form__plaintext col-sm-8">
                            <CobaltProfile :firstName="taskData.author?.first_name" :lastName="taskData.author?.last_name" />
                        </span>
                    </div>   
                    <div class="row mb-4">
                        <label class="col-sm-4 cobalt-task-form__label" for="taskDate"><img src="@/assets/images/dashboard/task-calendar.svg"> {{ $t("UI.task.date") }}</label>
                        <span class="cobalt-task-form__plaintext col-sm-8">
                            <DatePicker
                                v-model:value="datePlannedStart"
                                :placeholder="this.$t('UI.selectDate')"
                                type="datetime"
                                value-type="timestamp"
                                show-time-header="true"
                                format="DD.MM.YYYY HH:mm"
                            />
                        </span>
                    </div>    
                    <div class="row mb-4">
                        <label class="col-sm-4 cobalt-task-form__label" for="taskDeadline"><img src="@/assets/images/dashboard/task-time.svg"> {{ $t("UI.task.deadline") }}</label>
                        <span class="cobalt-task-form__plaintext col-sm-8">
                            <DatePicker 
                                v-model:value="datePlannedEnd"
                                :placeholder="this.$t('UI.selectDate')"
                                type="datetime"
                                value-type="timestamp"
                                show-time-header="true"
                                format="DD.MM.YYYY HH:mm"    
                                :disabled-date="disabledBeforeStart"                        
                            />                         
                        </span>
                    </div>    
                    <div class="row mb-4">
                        <label class="col-sm-4 cobalt-task-form__label" for="taskPriority"><img src="@/assets/images/dashboard/task-priority.svg"> {{ $t("UI.task.priority") }}</label>
                        <span class="cobalt-task-form__plaintext col-sm-8">
                            <Multiselect 
                                v-model="selectedPriority" 
                                :placeholder="this.$t('UI.select')"
                                selectLabel=""
                                :options="priorityValues" 
                                :searchable="false"
                                track-by="value"
                                label="label"
                                selectedLabel=""
                                deselectLabel=""
                            >
                                <template #singleLabel="props">
                                    <span class="cobalt-task__priority" :style="{ 'background-color': props.option.bg, 'color': props.option.color }">
                                        {{ props.option.label }}
                                    </span>                                    
                                </template>
                                <template #option="props">
                                    <span class="cobalt-task__priority" :style="{ 'background-color': props.option.bg, 'color': props.option.color }">
                                        {{ props.option.label }}
                                    </span>
                                </template>
                            </Multiselect>
                        </span>
                    </div>    
                    <div class="row mb-4 align-items-start" v-if="taskData.id">
                        <label class="col-sm-4 cobalt-task-form__label" for="taskPriority"><img src="@/assets/images/dashboard/task-user.svg"> {{ $t("UI.task.performers") }}</label>
                        <span class="cobalt-task-form__plaintext col-sm-8">
                            <Multiselect 
                                v-model="selectedPerformers" 
                                :placeholder="this.$t('UI.select')"
                                :multiple="true"
                                :options="projectUsers" 
                                :class="['cobalt-task__multiselect']"
                                track-by="id"                              
                                selectLabel=""
                                label="first_name"
                                selectedLabel=""
                                deselectLabel="&#x2715;"
                                @remove="removePerformer"
                            >
                                <template #selection="{ values, search, isOpen }">
                                    <div class="cobalt-profile-list__wrapper">
                                        <div class="cobalt-profile-list__item" v-for="item in values">
                                            <CobaltProfile 
                                                :firstName="item.first_name" 
                                                :lastName="item.last_name" 
                                                :short="true"
                                            />
                                        </div>
                                    </div>
                                </template>                            
                                <template #option="props">
                                    <span class="cobalt-task__performer-dropdown-item">
                                        {{ props.option.first_name + ' ' + props.option.last_name }}
                                    </span>
                                </template>
                            </Multiselect>   
                        </span>
                    </div>  
                    <div class="mb-1">
                        <label>{{ $t("UI.backgroundColor") }}</label>
                    </div>
                    <div class="mb-4">
                        <div class="form-check form-check-inline" v-for="itemColor in colors">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions"
                                :checked="itemColor == taskData.color" @change="chColorTsk(itemColor)"
                                id="inlineRadio1" :value="itemColor"
                                :style="{ 'border-color': itemColor, 'border-width': '3px' }">
                            <label class="form-check-label" for="inlineRadio1"></label>
                        </div>
                    </div>  
                                       
                </div>

                <div class="tab-pane fade show" :class="{ active: activeTab === 'files' }" id="st-files" role="tabpanel" aria-labelledby="st-files-tab">
                    <div class="flex flex-wrap cobalt-uploader">
                        <uploader
                            ref="uploaderRef"
                            :auto="false"
                            @file-added="onFilesAdded"
                            @file-error="onFilesError"
                        >
                            <uploader-drop>
                                <span>{{ $t("UI.task.dropOrSelectFiles") }}</span>
                                <uploader-btn>{{ $t("UI.task.selectFiles") }}</uploader-btn>
                            </uploader-drop>
                            <uploader-list></uploader-list>                                    
                        </uploader>
                        
                        <div class="uploader-list">
                            <div class="uploader-file" v-for="file in this.taskData.files" :key="file.id">
                                <div class="uploader-file-name"><i class="uploader-file-icon" icon="unknown"></i><a target="_blank" :href="Config.SERVER + 'web/' + file.filePath">{{ file.name }}</a></div>
                                <div class="uploader-file-actions">
                                    <a class="btn btn-base me-1" target="_blank" :download="file.filePath" :href="Config.SERVER + 'web/' + file.filePath">
                                        <img src="@/assets/images/dashboard/base-download-dark.svg">
                                    </a>
                                    <div class="devider">|</div>
                                    <button class="btn btn-base btn-warning me-1" @click="onFileDelete(file.id)">
                                        <img src="@/assets/images/dashboard/task-remove.svg">
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tab-pane fade show h-100" :class="{ active: activeTab === 'comments' }" id="st-comments" role="tabpanel" aria-labelledby="st-comments-tab">
                    <div class="flex flex-wrap cobalt-comments__wrapper">
                        <div class="cobalt-comments__list">
                            <div class="comment-group" v-for="date in sortedDates" :key="date">
                                <h3 class="comment-group__header">{{ date }}</h3>
                                <div class="comment-group__body">
                                    <div class="comment-wrapper" v-for="comment in groupedComments[date]" :key="comment.id">
                                        <div class="comment" :class="{'my-comment' : this.activeUser.id == comment.user_id}">
                                            <div class="comment-content p-2">
                                                <div class="replyToComment" v-if="comment.reply">
                                                    <div class="replyToComment__content">
                                                        <span class="author">{{ comment.reply.user.name }}</span> 
                                                        {{ comment.reply.body }}
                                                    </div>                                                    
                                                </div>
                                                <p class="author">{{ comment.user?.name }}</p> 
                                                <p class="small mb-0">
                                                    {{ comment.body }}
                                                </p>
                                            </div>
                                            <span>{{ localeTime(comment.date_create) }}</span>
                                        </div>
                                        <div class="comment-actions">
                                            <button class="btn btn-base me-1" @click="this.replyComment(comment)">
                                                <img src="@/assets/images/dashboard/base-reply.svg">
                                            </button>
                                            <div class="dropdown">
                                                <button class="btn btn-link btn-sm text-body-tertiary" :id="'commentDropdownMenuLink' + comment.id" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <img src="@/assets/images/dashboard/more-vertical.svg">
                                                </button>
                                                <ul class="dropdown-menu" :aria-labelledby="'commentDropdownMenuLink' + comment.id">
                                                    <li class="dropdown-item">
                                                        <button type="button" class="btn btn-link btn-sm me-2 text-body-tertiary" @click="this.replyComment(comment)">
                                                            {{ $t("UI.task.commentReplay") }}
                                                        </button>
                                                    </li>
                                                    <li class="dropdown-item">
                                                        <button type="button" class="btn btn-link btn-sm me-2 text-body-tertiary">
                                                            {{ $t("UI.task.commentEdit") }}
                                                        </button>                                                            
                                                    </li>
                                                    <li class="dropdown-item">
                                                        <button type="button" class="btn btn-link btn-sm me-2 text-body-tertiary" @click="this.deleteComment(comment.id)">
                                                            {{ $t("UI.task.commentDelete") }}
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>
                        <div class="cobalt-comments__footer">
                            <div class="replyPanel" v-if="replyToComment">
                                <div class="replyToComment">
                                    <div class="replyToComment__content">
                                        <span class="author">{{ replyToComment.user.name}}</span>
                                        {{ replyToComment.body }}
                                    </div>
                                </div>
                                <button type="button" class="cobalt-toolbar__icon-btn" @click="this.replyToComment = null">
                                    <img src="@/assets/images/dashboard/base-close-dark.svg" />
                                </button>
                            </div>
                            <div class="cobalt-comments__input">
                                <textarea v-model="this.comment" ref="commentTextInput" @keypress.enter="sendComment"></textarea>
                            </div>
                            <div class="cobalt-comments__input-actions">
                                <div class="cobalt-comments__input-actions-commands"></div>
                                <button class="btn btn-filled cobalt-task__comment-add" :class="this.comment.length == 0 ? 'disabled' : ''" type="button" @click="sendComment">
                                    {{ $t("UI.task.sendComment") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>                       
        <!-- 
        <div v-if="datUpdateStatus.errorText != ''">
            <p class="mt-2 mb-0 text-danger">{{ datUpdateStatus.errorText }}</p>
        </div>
        -->
    </form>
</template>

<script>
import CobaltProfile   from "@/components/dashboard/parts/CobaltProfile.vue"
import CobaltPerformer from "@/components/dashboard/parts/CobaltPerformer.vue"
import CobaltPerformersList from "@/components/dashboard/parts/CobaltPerformersList.vue"
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import Multiselect from 'vue-multiselect'
import { ref } from 'vue'
import { VueDraggable } from 'vue-draggable-plus'
import { mapGetters, mapActions } from 'vuex'
import { localeDate, localeTime, localeDateTime, priorityOptions, eventDate } from '@/utils/format'
import Config from '../../../config.js'

export default {
    components: {
        DatePicker,
        Multiselect,
        CobaltProfile,
        CobaltPerformer,
        CobaltPerformersList
    },    
    props: {
        activeUser: {
            type: Object,
            required: true
        },    
        taskData: {
            type: Object,
            required: true
        },
        projectUsers: {
            type: Array,
            required: true
        },
        colors: {
            type: Array,
            required: true
        },   
        parentId: {
            type: Number,
            required: true
        }      
    },    
    data() {
        return {
            Config,
            comment: '',
            activeTab: 'params',
            replyTo: 0,
            replyToComment: null,
            files: [],
            tags: []
        }
    },
    computed: {
        datePlannedStart: {
            get() {
                return this.taskData.date_planned_start ? this.taskData.date_planned_start * 1000 : null
            },
            set(option) {
                if (option) {
                    this.taskData.date_planned_start = option / 1000
                    this.updateTaskDate()
                } else {
                    this.taskData.date_planned_start = null
                    this.clearTaskDate()
                }
            }
        },
        datePlannedEnd: {
            get() {
                return this.taskData.date_planned_end ? this.taskData.date_planned_end * 1000: null
            },
            set(option) {
                if (option) {
                    this.taskData.date_planned_end = option / 1000
                    this.updateTaskDate()
                } else {
                    this.taskData.date_planned_end = null
                    this.clearTaskDate()
                }
            }
        }, 
        priorityValues() {
            return [
                { value: 0, bg: "#ebebeb", color: "#262626", label: this.$t('UI.task.priority_0') },
                { value: 1, bg: "#FCEBE6", color: "#9B2406", label: this.$t('UI.task.priority_1') },
                { value: 2, bg: "#fafadb", color: "#816403", label: this.$t('UI.task.priority_2') },
                { value: 3, bg: "#dbfae3", color: "#03590b", label: this.$t('UI.task.priority_3') },
                { value: 4, bg: "#d4edfc", color: "#0e4f9a", label: this.$t('UI.task.priority_4') }
            ];
        },               
        selectedPriority: {
            get() {
                return this.priorityValues.find(option => option.value === this.taskData.priority) || null
            },
            set(option) {
                this.taskData.priority = option ? option.value : null
            }
        },
        selectedPerformers: {
            get() {
                return this.taskData.performer
            },
            set(option) {
                if (Array.isArray(option) && option.length > 0) {
                    const updatedPerformers = [...option]
                    this.taskData.performer = updatedPerformers
                    this.updateTaskPerformer(true, true, option[option.length - 1])
                }
            }            
        },
        groupedComments() {
            return this.taskData.comments?.reduce((groups, comment) => {
                const date = comment.date_create * 1000;
                const fDate = new Date(date).toLocaleDateString('ru-RU')
                if (!groups[fDate]) {
                    groups[fDate] = [];
                }
                groups[fDate].push(comment);
                return groups;
            }, {});
        },
        selectedTags: {
            get() {
                return this.taskData.tags
            },
            set(option) {
                if (Array.isArray(option) && option.length > 0) {
                    const updatedTags = [...option]
                    this.taskData.tags = updatedTags
                }
            }   
        },
        sortedDates() {
            if (!this.groupedComments) {
                return []
            }
            return Object.keys(this.groupedComments).sort((a, b) => a - b).reverse()
        }         
    },
  
    methods: {
        ...mapActions('board', ['updatSubTsk', 'updatSubTskDate', 'clearSubTskDate', 'listPerformers', 'assignPerformer', 'detachPerformer', 'uploadSubFiles', 'deleteSubFile', 'addTaskComment', 'searchTags']),
        localeDate,
        localeTime,
        localeDateTime,
        initialize() {
            this.activeTab = 'params'
            this.replyToComment = null
            this.$refs.uploaderRef.fileList = []
        },
        disabledBeforeStart(date) {
            const start = new Date(this.taskData.date_planned_start * 1000);
            return date < start;
        },           
        chColorTsk(val) {
            this.taskData.color = val
        },         
        async updateSubTask() {
            let updateResult = await this.updatSubTsk(this.taskData)

            return updateResult
        },
        async updateTaskPerformer(operation, subtask, performer) {
            if (operation) {
                await this.assignPerformer({
                    task_id: this.taskData.id,
                    subtask: subtask,
                    performer_id: performer.id,
                })
            } else {
                await this.detachPerformer({
                    task_id: this.taskData.id,
                    subtask: subtask,
                    performer_id: performer.id,
                })                
            }
        },
        removePerformer(option, id) {
            const updatedPerformers = this.taskData.performer.filter(item => item.id !== option.id)
            this.taskData.performer = updatedPerformers
            this.updateTaskPerformer(false, true, option)
        },        
        async updateTaskDate() {
            if (!this.taskData.id) {
                await this.updateSubTask();
            }
            await this.updatSubTskDate(this.taskData)
        },
        async clearTaskDate() {
            await this.clearSubTskDate(this.taskData)
        },   
        onFilesAdded(files) {
            this.files = this.files.concat(files);
            this.doUploadFiles();
        },  
        onFileDelete(id) {
            this.doDeleteFile(id)

            this.taskData.files = this.taskData.files.filter(function( obj ) {
                return obj.id !== id;
            });            

            this.$emit('refresh')
        },  
        async doDeleteFile(id) {
            try {
                const response = this.deleteSubFile(id)

                if (response) {
                    console.log('File remove successfully:', response);
                } else {
                    console.error('File remove failed:', response);
                }
            } catch (error) {
                console.error('Error removing files:', error);
            }
        },         
        async doUploadFiles() {
            if (this.files.length === 0) {
                console.log('No files to upload.');
                return;
            }

            const formData = new FormData();

            this.files.forEach((file, index) => {
                formData.append(`files[${index}]`, file.file);
            });

            formData.append('id', this.taskData.id);

            try {
                const response = this.uploadSubFiles(formData)

                if (response) {
                    console.log('Files uploaded successfully:', response);
                } else {
                    console.error('File upload failed:', response);
                }
            } catch (error) {
                console.error('Error uploading files:', error);
            }

            this.files = [];
        },
        async sendComment() {
            if (this.comment.length == 0) {
                return
            }

            let commentData = {
                'body': this.comment,
                'task_id': this.taskData.id,
                'isSubtask': true
            }

            if (this.replyToComment) {
                commentData['replyTo'] = this.replyToComment.id
            }

            let result = await this.addTaskComment(commentData)
            
            this.replyToComment = null

            if (result) {
                this.comment = ''
                this.taskData.comments = result
            }

            this.$emit('refresh')
        }, 
        async deleteComment(id) {
            let commentData = {
                'id': id,
                'isSubtask': true
            }

            let result = await this.deleteTaskComment(commentData)
            if (result) {
                this.taskData.comments = result
            }
        },
        replyComment(comment) {
            this.replyToComment = comment
            this.$refs.commentTextInput.focus()
        },        
        async updateTaskTags() {

        },
        async doSearchTags(search) {
            this.tags = this.searchTags(search)
        },
        onFilesError(file, error) {
            console.error('File upload failed:', file, error);
        }, 
        setActiveTab(tab) {
          this.activeTab = tab
        }                                   
    }
}
</script>

<style></style>