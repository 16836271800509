<template>

    <CobaltDashboard :info="null" :company="null" :withFilterBar="false" contentClasses="mt-3 ps-0">

        <div>
            <div class="home">
                <div class="welcome-panel m-0 mb-4">
                    <div class="welcome-panel__body">
                        <h3>Welcome!</h3>
                        <p class="m-0">Hi, welcome to task management dashboard</p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-9">
                        <div class="welcome-block">
                            <img src="@/assets/images/dashboard/home/welcome.png">
                            <div class="welcome-text">
                                <h2>Welcome to Management</h2>
                                <p>Project activity will be updated here.<br />Click on the name section to set your configuration.</p>     
                            </div>
                        </div>

                        <div class="welcome-panel">
                            <div class="welcome-panel__header">
                                <div class="welcome-panel__header-header">Recent project Statistics</div>
                                <div class="welcome-panel__header-actions">View all</div>
                            </div>

                            <div class="welcome-panel__body">
                                <div class="single-project">
                                    <div class="progress-bar"></div>
                                    <div class="single-project__name">
                                        <h3>Project name, long title</h3>
                                        <div class="cobalt-task__performer"><div class="cobalt-profile-list__wrapper mt-2"><div class="cobalt-profile-list__item"><span class="cobalt-profile cobalt-profile-small me-1" style="background-color: rgb(179, 66, 0);">DC</span></div><div class="cobalt-profile-list__item"><span class="cobalt-profile cobalt-profile-small me-1" style="background-color: rgb(96, 6, 76);">КК</span></div></div></div>
                                    </div>
                                    <div class="single-project__limit">
                                        <span class="days-left">2 days left</span>
                                    </div>                                                                        
                                </div>

                                <div class="single-project">
                                    <div class="progress-bar"></div>
                                    <div class="single-project__name">
                                        <h3>Landing Page design</h3>
                                        <div class="cobalt-task__performer"><div class="cobalt-profile-list__wrapper mt-2"><div class="cobalt-profile-list__item"><span class="cobalt-profile cobalt-profile-small me-1" style="background-color: rgb(179, 66, 0);">DC</span></div><div class="cobalt-profile-list__item"><span class="cobalt-profile cobalt-profile-small me-1" style="background-color: rgb(96, 6, 76);">КК</span></div></div></div>
                                    </div>
                                    <div class="single-project__limit">
                                        <span class="days-left">2 days left</span>
                                    </div>                                                                        
                                </div>
                                
                                <div class="single-project">
                                    <div class="progress-bar"></div>
                                    <div class="single-project__name">
                                        <h3>Responsive mobile redesign</h3>
                                        <div class="cobalt-task__performer"><div class="cobalt-profile-list__wrapper mt-2"><div class="cobalt-profile-list__item"><span class="cobalt-profile cobalt-profile-small me-1" style="background-color: rgb(179, 66, 0);">DC</span></div><div class="cobalt-profile-list__item"><span class="cobalt-profile cobalt-profile-small me-1" style="background-color: rgb(96, 6, 76);">КК</span></div></div></div>
                                    </div>
                                    <div class="single-project__limit">
                                        <span class="days-left">2 days left</span>
                                    </div>                                                                        
                                </div>     
                                
                                <div class="single-project">
                                    <div class="progress-bar"></div>
                                    <div class="single-project__name">
                                        <h3>Mobile Application design</h3>
                                        <div class="cobalt-task__performer"><div class="cobalt-profile-list__wrapper mt-2"><div class="cobalt-profile-list__item"><span class="cobalt-profile cobalt-profile-small me-1" style="background-color: rgb(179, 66, 0);">DC</span></div><div class="cobalt-profile-list__item"><span class="cobalt-profile cobalt-profile-small me-1" style="background-color: rgb(96, 6, 76);">КК</span></div></div></div>
                                    </div>
                                    <div class="single-project__limit">
                                        <span class="days-left">2 days left</span>
                                    </div>                                                                        
                                </div>                                  
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <DatePicker  
                            locale="en-EN"
                            firstDayOfWeek="monday"
                        />

                        <div class="welcome-panel transparent p-0 ">
                            <div class="welcome-panel__header">
                                <div class="welcome-panel__header-header">Activities</div>
                                <div class="welcome-panel__header-actions">View all</div>
                            </div>

                            <div class="welcome-panel__body">
                                <div class="single-activity">
                                    <div class="single-activity__name">
                                        <h3>Ellie joined team developers</h3>
                                        <span>04 April, 2021 | 04:00 PM</span>
                                    </div>                                           
                                </div>

                                <div class="single-activity">
                                    <div class="single-activity__name">
                                        <h3>Jenny joined team HR</h3>
                                        <span>04 April, 2021 | 04:00 PM</span>
                                    </div>                                           
                                </div>
                                
                                <div class="single-activity">
                                    <div class="single-activity__name">
                                        <h3>Adam got employee of the month</h3>
                                        <span>04 April, 2021 | 04:00 PM</span>
                                    </div>                                           
                                </div>
                                
                                <div class="single-activity">
                                    <div class="single-activity__name">
                                        <h3>Robert joined team design</h3>
                                        <span>04 April, 2021 | 04:00 PM</span>
                                    </div>                                           
                                </div>                                
                            </div>                        
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </CobaltDashboard>

</template>

<script>

import CobaltDashboard from "@/components/dashboard/CobaltDashboard.vue";
import { mapGetters, mapActions } from 'vuex';
import { DatePicker } from "qalendar";
import Config from '../config.js'
import "qalendar/dist/style.css";

export default {
    components: {
        CobaltDashboard,
        DatePicker
    },
    data() {
        return {
            Config,
            compInfo: null,
        }
    },
    beforeMount: function () {
        //this.getListComp()
    },

    methods: {
        ...mapActions('company', ['list', 'addCompany', 'delete']),
        ...mapGetters('company', ['getList']),      
    }

}
</script>

<style>
    .home {
        width: 100%;
        height: 100%;
    }
    .welcome-block {
        position: relative;
        display: flex;
        align-items: center;        
        border-radius: 8px;
        color: #FFF;
        background-color: rgb(34,193,195);
        background-image: url("../../src/assets/images/dashboard/home/bg.png") no-repeat right top;        
        background: url("../../src/assets/images/dashboard/home/bg.png") no-repeat right top, linear-gradient(155deg, rgba(34,193,195,1) 0%, rgba(20,41,65,1) 100%);
        margin-top: 35px;
    }
    .welcome-block img {
        margin-top: -25px;
    }
    .welcome-panel {
        background-color: #e8f2fd;
        border-radius: 8px;
        margin-top: 25px;
        padding: 15px;
    }
    .welcome-panel.transparent {
        background-color: transparent;
    }
    .welcome-panel__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
    }
    .welcome-panel__header-header {
        font-weight: 600;
        font-size: 18px;
    }    
    .date-picker.date-picker-root {
        width: auto;
    } 
    .date-picker .week span {
        border-radius: 10px;
    }    
    .date-picker.date-picker-root .week span.is-weekend {
        color: #F44336;
    }    
    .date-picker-root .date-picker__week-picker:not(.is-light-mode .date-picker__week-picker) {
        background-color: #ffffff;
        border-color: transparent;
        box-shadow: none;
        padding: 0;
    }
    .date-picker__week-picker-navigation {
        font-weight: 500 !important;
        text-transform: uppercase;
        border-bottom: 1px solid #ededed;
        padding: 20px 0;
        position: relative;
    }
    .date-picker__toggle-mode {
        position: absolute;
        left: 0;
    }
    svg.svg-inline--fa.fa-circle-chevron-left.is-icon.is-chevron-left {
        position: absolute;
        right: 32px;
    }
    svg.svg-inline--fa.fa-circle-chevron-right.is-icon.is-chevron-right {
        position: absolute;
        right: 0;
    }  
    .date-picker .week span {
        border-radius: 10px !important;
    }    
    .progress-bar {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: 
            radial-gradient(closest-side, #e8f2fd 92%, transparent 95% 100%),
            conic-gradient(rgb(34,193,195) 75%, rgb(255, 255, 255) 0);    
    }
    .progress-bar::before {
        content: "75%";
    }
    .single-project,
    .single-activity {
        display: flex;
        border: 1px solid #c9d9e9;
        border-radius: 8px;
        margin-bottom: 10px;
        padding: 10px 20px;
        align-items: center;
    }  
    .single-project__name {
        padding-left: 15px;
        flex:1;
    }   
    .single-project__name h3 {
        margin: 0;
        font-size: 16px;
    }
    .days-left {
        padding: 7px 15px;
        background-color: #1b7280;
        color: #FFF;
        border-radius: 8px;
        font-size: 12px;
    }
    .single-activity {
        background-color: #e8f2fd;
        border: none;
    }
    .single-activity__name h3 {
        padding-top: 5px;
        font-size: 16px;
        font-weight: 600;
    }
    .single-activity__name span {
        font-size: 14px;
    }
</style>