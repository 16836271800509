<template>
    <div class="d-lg-flex half">
      <div 
        class="bg order-1 order-md-2 auth-bg d-none d-lg-block" 
        :style="{'background-image': 'url(' + require('@/assets/images/auth/' + $i18n.locale +  '/welcome-np.svg') + ')'}"
      >
      </div>
      <div class="contents order-2 order-md-1">
  
          <div class="container">
              <div class="row align-items-center justify-content-center">
                  <div class="col-md-7 text-center">              

                    <div class="cobalt-auth mb-4 text-lg-center">
                        <img :src="require('@/assets/images/auth/' + $i18n.locale +  '/login.svg')" :alt="$t('auth.registration')" />
                    </div>

                    <form class="d-flex flex-column align-items-center">
                        <div class="form-group cobalt-form-group d-flex justify-content-center has-feedback mb-3">
                            <div class="cobalt-input-group">
                                <span class="input-group-text">
                                    <img src="@/assets/images/auth/name.svg" />
                                </span>                            
                                <input type="text" class="form-control cobalt-flat-input cobalt-input-auth" :placeholder="$t('auth.username')"
                                    id="exampleInputLogin" aria-describedby="loginHelp" v-model="authData.login">
                            </div>
                            <small id="loginHelp" class="form-text text-muted"></small>
                        </div>
                        <div class="form-group cobalt-form-group d-flex justify-content-center has-feedback mb-5">
                            <div class="cobalt-input-group">
                                <span class="input-group-text">
                                    <img src="@/assets/images/auth/password.svg" />
                                </span>                             
                                <input type="password" class="form-control cobalt-flat-input cobalt-input-auth" :placeholder="$t('auth.password')"
                                    id="exampleInputPassword" v-model="authData.password">
                            </div>
                        </div>
                        <div class="cobalt-form-group d-flex">
                            <button type="button" class="btn cobalt-btn-primary btn-lg flex-fill" @click="tryLogin">{{ $t("auth.enter") }}</button>
                            <button type="button" class="btn btn-link p-0 ms-3" @click="tryRegister">{{ $t("auth.forgotPassword") }}</button>
                        </div>
                        <div v-if="authData.errorText != ''">
                            <p class="mt-2 mb-0 text-danger">{{ authData.errorText }}</p>
                        </div>
                        <div class="mt-4">
                            <p class="clarification mt-2">
                                <span>{{ $t("auth.notRegisteredYet") }}</span>
                                <button type="button" class="btn btn-link p-0 ms-2" @click="tryRegister">{{ $t("auth.register") }}</button>
                            </p>
                            <LanguageSwitcher />
                        </div>                        
                    </form>
                </div>
            </div>
        </div>
    </div> 
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LanguageSwitcher from '../components/LanguageSwitcher.vue'

export default {
    components: {
        LanguageSwitcher
    },
    data() {
        return {
            authData: {
                login: '',
                password: '',
                errorText: ''
            }
        }
    },
    computed: {

    },
    methods: {
        ...mapActions('user', ['login']),
        async tryLogin() {
            let login = await this.login({
                login: this.authData.login,
                password: this.authData.password
            });

            if (login) {
                this.$router.push({ name: 'dashboard' })
            }
            else {

                this.authData.errorText = $t("error.wrongPassword");
            }
        },
        tryRegister(){
            this.$router.push({ name: 'register' })
        }
    }
}
</script>

<style>
.btn-log {
    --bs-btn-color: #fff !important;
    --bs-btn-bg: #880652 !important;
    --bs-btn-border-color: #065588 !important;
    --bs-btn-hover-color: #fff !important;
    --bs-btn-hover-bg: #054068 !important;
    --bs-btn-hover-border-color: #054068 !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #054068;
    --bs-btn-active-border-color: #054068;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #065588;
    --bs-btn-disabled-border-color: #065588;
}

.form-control-log {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #C8D8E8;
    background-clip: padding-box;
    border: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.form-control-log:focus {
    color: #000000;
    background-color: #a4b2c0;
    outline: 0;
    box-shadow: 0 0 0 0.1rem rgba(13, 110, 253, 0);
}
</style>